import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
	list        : [],
	current     : {},
	totalCount  : 0,
	currentPage : 1,
	perPage     : 25,
	dataLoading : false,
	options     : [],
	sortCol     : 'isin',
	sortDir     : 'asc',
	filters		: undefined,
	pagination  : true
}

const module_name = 'instrumentEquity'
const url = Urls.instrumentEquity

const actions = {
	getList({commit, dispatch, rootState}) {
		let props = {
			url      : url,
			name     : module_name,
		}

		return dispatch('app/get_list', props, { root: true })
	},
}

const mutations = {
	set(state, {type, items}) {
		state[type] = items
	},
	set_filter(state, {type, items}) {
		state.filters[type].value = items
	},
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
