import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list: []
}

const actions = {
    getFavorites({dispatch, commit}, params) {
        return Vue.axios.get(Urls.userReportsFavorites).then((response) => {
            commit('set', {type: 'list', items: response.data._items})
            return response.data._items
        })
    },
    addFavorite({dispatch, commit}, params){
        return Vue.axios.post(Urls.userReportsFavorites, {item: params.item}).then(response => {
            dispatch('getFavorites')
        })
    },
    deleteFavorite({dispatch, commit}, params) {
        return Vue.axios.patch(`${Urls.userReportsFavorites}`, {item: params.item}).then(response => {
            dispatch('getFavorites')
        })
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
