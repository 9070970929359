import Vue  from 'vue'
import Urls from '@/../config/url.js'

const module_name = 'portfolioAccounts'
const url = Urls.portfolioAccounts

const state = {
    dataLoading          : false,
}

const actions = {
    getList({commit, dispatch}, params){
        let get_url = url + "?where=" + JSON.stringify(params)
        return new Promise((resolve, reject) => {
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}}).then((response) => {
                let body = response.data._items
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    addItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    updateItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    getBalance({dispatch}, params){
        return new Promise((resolve, reject) => {
            return Vue.axios.post(Urls.accountsBalance, params).then(response => {
                resolve(response.data._items)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    }

    // getReportBalance({commit, dispatch, rootState}, params) {
    //     commit('set', {type: 'reportBalanceLoading', items:true})
    //     commit('set', {type: 'reportBalanceList', items:[]})
    //     commit('app/set', {type: 'serverError', items: undefined}, { root: true });

    //     return Vue.axios.post(Urls.reportAccountsBalance, params).then(response => {
    //         let body = response.data._items
    //         commit('set', {type: 'reportBalanceLoading', items:false})
    //         commit('set', {type: 'reportBalanceList', items:body})
    //     })
    //     .catch(error => {
    //         dispatch('app/process_error', error, { root: true })
    //         commit('set', {type: 'reportBalanceLoading', items:false})
    //     })
    // },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
