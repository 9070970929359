<template>
    <transition name="side-bar" v-if="sidebar">
        <div id="side-bar">
            <div id="burger-close" @click="sidebar=false">
                <svg-icon icon-class="close" />
            </div>
            <el-menu
                :default-active="$route.path"
                mode="vertical"
                class="menu-nav-menu"
                unique-opened
                router
                size="mini"
                collapse>

                <template v-for="route in routes">
                    <el-menu-item
                        @click="open_menu_item(route)"
                        v-if="(!route.children || !route.children.length) && route.meta.sidebar">
                        <svg-icon :icon-class="route.meta.icon" v-if="route.meta && route.meta.icon"></svg-icon>
                        <div class="menu-title">{{route.meta.title}}</div>
                    </el-menu-item>

                    <el-submenu
                        :index="route.path"
                        v-if="route.children && route.children.length && route.meta.sidebar"
                        popper-class="sidebar">
                        <template slot="title">
                            <svg-icon :icon-class="route.meta.icon" v-if="route.meta && route.meta.icon"></svg-icon>
                            <div class="menu-title">{{route.meta.title}}</div>
                        </template>

                        <template v-for="subroute in route.children">
                            <el-menu-item-group v-if="subroute.children && subroute.children.length && subroute.meta.sidebar">
                                <div slot="title">{{subroute.meta.title}}</div>
                                <el-menu-item
                                    v-for="subsubroute in subroute.children"
                                    :key="subsubroute.path"
                                    :index="subsubroute.path">
                                    {{subsubroute.meta.title}}
                                </el-menu-item>
                            </el-menu-item-group>

                            <el-menu-item
                                :index="subroute.path"
                                v-if="(!subroute.children || !subroute.children.length) && subroute.meta.sidebar"
                                v-bind:class="{ 'custom-menu-item': subroute.name === 'library_clients' }">
                                {{subroute.meta.title}}
                            </el-menu-item>
                        </template>

                    </el-submenu>
                </template>

            </el-menu>
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        sidebar: {
            get() { return this.$store.state.app.sidebar; },
            set(value) { this.$store.commit('app/set', {type: 'sidebar', items:value}); },
        },

        settings_dialog: {
            get() { return this.$store.state.app.settings_dialog; },
            set(value) { this.$store.commit('app/set', {type: 'settings_dialog', items:value}); },
        },

        ...mapState({
            routes : state => state.app.routes,
        }),
    },

    methods: {
        open_menu_item(route) {
            if(route.name === 'settings') {
                this.settings_dialog = true
                this.sidebar = false
            } else {
                this.$router.push(route.path)
            }
        }
    }
}
</script>
