var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAuthed)?_c('div',[_c('div',{staticClass:"dark",attrs:{"id":"app"}},[_c('el-tabs',{ref:"TabsBar",attrs:{"type":"card","id":"tabs-bar"},on:{"tab-click":_vm.tab_select,"tab-remove":_vm.tab_remove},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},_vm._l((_vm.tabs),function(item,index){return _c('el-tab-pane',{key:item.name,ref:"tabs",refInFor:true,attrs:{"name":item.name,"closable":item.closable,"path":item.path,"query":item.query,"content_type":item.content_type,"tab_with_props":item.tab_with_props,"route_name":item.route_name}},[_c('div',{staticClass:"tabs-bar-label",attrs:{"slot":"label"},slot:"label"},[(item.icon)?_c('svg-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                              content: item.title,
                              offset: -6,
                              classes: ['tabs-bar-label__tooltip'],
                            }),expression:"{\n                              content: item.title,\n                              offset: -6,\n                              classes: ['tabs-bar-label__tooltip'],\n                            }"}],attrs:{"icon-class":item.icon}}):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                              content: item.title,
                              offset: -6,
                              classes: ['tabs-bar-label__tooltip'],
                            }),expression:"{\n                              content: item.title,\n                              offset: -6,\n                              classes: ['tabs-bar-label__tooltip'],\n                            }"}],staticClass:"tabs-bar-label__text"},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('TitleBar',{on:{"selected-search-result":_vm.selected_search_result,"selected-history-tab":_vm.tab_history}}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"app-content spacer"},[_c('keep-alive',[_c(item.content,_vm._b({ref:item.name,refInFor:true,tag:"component",on:{"tab-title-change":function($event){return _vm.change_tab_title(item.name, $event)},"tab-query-change":function($event){return _vm.change_tab_query(item.name, $event)},"tab-close":function($event){return _vm.tab_remove(item.name)},"update-portfolio-data":_vm.update_portfolio_data}},'component',item.props,false))],1)],1),_c('WidgetsBar')],1)],1)}),1),_c('Logo',{ref:"Logo"})],1),_c('SideBar'),_c('SideBarOverlay'),_c('Settings')],1):_c('Login')],1)}
var staticRenderFns = []

export { render, staticRenderFns }