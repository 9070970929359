<template>
    <autocomplete
        :search="search"
        placeholder="Go to..."
        aria-label="Go to..."
        ref="autocomplete">
        <template
            #default="{
                rootProps,
                inputProps,
                inputListeners,
                resultListProps,
                resultListListeners,
                results,
                resultProps
            }">
            <div v-bind="rootProps" :class="{ 'autocomplete-clear-btn': value }">
                <input
                    v-on="inputListeners"
                    v-bind="inputProps"
                    :class="[
                        'autocomplete-input',
                        { 'autocomplete-input-no-results': noResults },
                        { 'autocomplete-input-focused': focused }
                    ]"
                    @focus="handleFocus"
                    @blur="handleBlur"/>
                <span class="autocomplete_clear" @click="clearSearch"><svg-icon icon-class="close_small" /></span>

                <ul
                    v-if="noResults && value.length > 1"
                    class="autocomplete-result-list"
                    style="position: absolute; z-index: 1; width: 100%; top: 100%;">
                    <li class="autocomplete-result">
                        No results found
                    </li>
                </ul>
                <ul v-if="!noResults" v-bind="resultListProps" v-on="resultListListeners">
                    <div class="search-result-section">
                        <div class="search-result-section-title">Instruments</div>
                        <div v-for="item in results_instrument" :key="item.isin" class="search-result-section-item" @click="handleSubmit(item)">
                            <div class="search-result-section-item-name" v-html="highlight(item.name)"></div>
                            <div class="spacer" />
                            <div class="search-result-section-item-note">{{item.asset}}</div>
                        </div>
                        <div v-if="results_instrument.length" class="search-result-section-see_more">
                            <el-button type="text" size="mini" @click="see_more('instrument')">See more</el-button>
                        </div>
                    </div>
                    <div class="search-result-section">
                        <div class="search-result-section-title">Clients</div>
                        <div v-for="item in results_clients" :key="item.id" class="search-result-section-item" @click="handleSubmit(item)">
                            <div class="search-result-section-item-name" v-html="highlight(item.name)"></div>
                        </div>
                        <div v-if="results_clients.length" class="search-result-section-see_more">
                            <el-button type="text" size="mini" @click="see_more('client')">See more</el-button>
                        </div>
                    </div>
                    <div class="search-result-section">
                        <div class="search-result-section-title">Other</div>
                        <div v-for="item in results_other"  class="search-result-section-item" @click="handleSubmit(item)">
                            <div class="search-result-section-item-name">
                                <div v-html="highlight(item.name)"></div>
                                 <div v-if="item.client"  class="search-result-section-item-subname">{{item.client.first_name}} {{item.client.last_name}}</div>
                            </div>
                            <div class="spacer" />
                            <div class="search-result-section-item-note">{{item.type}}</div>
                        </div>
                        <div v-if="results_other.length" class="search-result-section-see_more">
                            <el-button type="text" size="mini" @click="see_more('other')">See more</el-button>
                        </div>
                    </div>
                </ul>
            </div>
        </template>
      </autocomplete>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'

import Urls from '@/../config/url.js'

export default {
    components: {
        Autocomplete,
    },

    data() {
        return {
            focused            : false,
            value              : '',
            results            : [],
        }
    },

    computed: {
        noResults() {
            let list_is_empty = false

            list_is_empty = this.results.every(pack => pack.data.length === 0)

            return this.value && list_is_empty
        },
        results_instrument(){
            let instr = this.results.find(i => i.type === 'instr');
            return (instr) ? instr.data : []
        },
        results_clients(){
            let clients = this.results.find(i => i.type === 'clients');
            return (clients) ? clients.data : []
        },
        results_other(){
            let other = this.results.find(i => i.type === 'other');
            return (other) ? other.data : []
        },

        global_search: {
            get() { return this.$store.state.app.global_search; },
            set(value) { this.$store.commit('app/set', {type: 'global_search', items:value}); },
        },

        global_search_type: {
            get() { return this.$store.state.app.global_search_type; },
            set(value) { this.$store.commit('app/set', {type: 'global_search_type', items:value}); },
        },
    },
    methods: {
        search(input) {
            this.value = input
            if (input.length < 1) {
                this.results = []
            } else {

                let url = Urls.searchGlobalShort + '?term=' + this.value

                this.axios.get(url)
                    .then(response => {
                        this.results = response.data
                        this.global_search = this.value
                    })
                    .catch(e => {
                        console.log(e)
                        this.$message({
                            message: 'Cannot make search!',
                            type: 'error'
                        })
                    })

                // this.results = [
                //     {type: 'instr', data: [
                //         {name: 'RTKM', asset: 'Equity', isin:'1'},
                //         {name: 'RTKMP', asset: 'Equity', isin:'2'},
                //         {name: 'ROSN', asset: 'Equity', isin:'3'},
                //         {name: 'RSTI', asset: 'Equity', isin:'4'},
                //         {name: 'RUAL', asset: 'Equity', isin:'5'},
                //         {name: 'RVLV', asset: 'Equity', isin:'6'},
                //         {name: 'VLR', asset: 'Equity', isin:'7'},
                //     ]},{type: 'clients', data: [
                //         {name: 'Renat Ibragimov', id:'1'},
                //         {name: 'Roman Sokolov', id:'2'},
                //         {name: 'Vladimir Ryabchenko', id:'3'},
                //     ]},{type: 'other', data: [
                //         {name: 'Rabota moey zheny', type: 'Strategy'},
                //         {name: 'Rabota moey byvshey zheny', type: 'Risk report'},
                //         {name: 'Risk', type: 'Risk report'},
                //         {name: 'RUR', type: 'currency'},
                //     ]},
                // ]
            }
            return this.results
        },

        handleFocus() {
            this.focused = true
        },

        handleBlur() {
            this.focused = false
        },

        clearSearch(){
            this.focused = false
            this.$refs.autocomplete.value = ''
            this.value = ''
        },

        handleSubmit(result){
            this.clearSearch();
            this.$emit('selected', result)
        },

        highlight(str){
            if (!str)
                return;
            const n = str.toUpperCase();
            const q = this.value.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return str; // bail early
            }
            const l = q.length;
            return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
        },

        see_more(global_search_type){
            this.$router.push({ name: 'search' })
            this.$refs.autocomplete.handleHide()
            this.$refs.autocomplete.value = ''
            this.value = ''
            this.global_search_type = global_search_type;
            if (this.$root.$children[0].$refs.search && this.$root.$children[0].$refs.search[0]){
                this.$root.$children[0].$refs.search[0].set_values();
            }
        },
    }
}
</script>
