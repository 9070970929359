import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list                  : [],
    current               : {},
    dataLoading           : false,
    countLoading          : false,
    countQuantity         : 0,
    countEachCondition    : [],
    availableDates        : [],
    availableDatesLoading : false,
    history_list          : [],
}

const actions = {
    searchIsinDates({commit, dispatch, rootState}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.searchIsinDates, {params: params})
            .then((response) => {
                resolve(response.data._items)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    searchIsinFields({commit, dispatch, rootState}, asset){
        let params = {ts : new Date().getTime()}
        let url = (asset === 'bond') ? Urls.sourcesBondFields : Urls.sourcesEquityFields;
        url += '?where=' + JSON.stringify({
            filters_use: true,
        })
        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {params: params})
            .then((response) => {
                resolve(response.data._items)
                resolve([])
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    searchIsinCount({commit, dispatch, rootState}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.searchIsinCount, params)
            .then((response) => {
                resolve(response.data.step_indexes)
                resolve([])
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    searchIsinResult({commit, dispatch, rootState}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.searchIsinResult, params)
            .then((response) => {
                resolve(response.data._items)
                resolve([])
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    searchIsinResultHistory({commit, dispatch, rootState}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.searchIsinResultHistory, params)
                .then((response) => {
                    resolve(response.data._items)
                    resolve([])
                })
                .catch(error => {
                    dispatch('app/process_error', error, { root: true })
                    reject(error);
                })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    and(a, b){
	    return new Set([...a].filter(x => b.has(x)));
    },

    or(a, b){
	    return new Set([...a, ...b]);
    },
    
    condIndex(param, indexes){
        //console.log(Object.entries(indexes))      
        for ([n, value] of Object.entries(indexes)) {
    	let cond = value['cond'];
    	
    	console.log(`n: ${n}`)
    	console.log(`param: ${JSON.stringify(param)}`)
    	console.log(`cond: ${JSON.stringify(cond)}`)
    	let keys = Object.keys(cond);
    	console.log(`keys: ${JSON.stringify(keys)}`)
    	if (keys.every(k => param[k] == cond[k])){
    	    console.log(`found: ${value}`);
    	    return value;
    	}
        }
        
        throw `Index not found for condition: ${JSON.stringify(param)}`;
    },
    
    queryIndex(params, indexes){
        let ors = []
        let ands = []
        console.log(`OEI: ${JSON.stringify(indexes)}`)      
        params.forEach(p => {
    	cset = condIndex(p, indexes)['set'];
    	if (p['rule'] == "AND"){
    	    if (ors.length > 0){
    		ands.push(ors.reduce(or))
    		ors = []    
    	    }
    	}
    	
    	ors.push(cset)
        })
        
        if (ors.length > 0){
    	ands.push(ors.reduce(or))
        }
        
        return ands.reduce(and)
    },
    
    applyIdsFilter(sourceDataset, idkey, ids){
        return sourceDataset.filter(i => ids.has(i[idkey]))
    } 	
    
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
