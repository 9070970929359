import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui'

const module_name = 'portfolio'
const url = Urls.portfolio
const url_del = Urls.portfolioDelete

const state = {
    dataLoading     : false,
}

var preprocess = function(itm) {
    delete itm.data
    if (!itm.pl) itm.pl = 0;
    return itm
}

const actions = {
    getSummaryPlain({commit, dispatch}, params){
        let get_url = Urls.portfolioSummary + "?where=" + JSON.stringify(params)
        return new Promise((resolve, reject) => {
            commit('set', {type: 'summaryLoading', items:true})
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}}).then((response) => {
                let body = response.data._items
                let list = body
                commit('set', {type: 'summaryLoading', items:false})
                resolve(list)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                commit('set', {type: 'summaryLoading', items:false})
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getList({commit, dispatch}, params){
        let get_url = url + "?where=" + JSON.stringify({client_id:params.client_id})

        return new Promise((resolve, reject) => {
            commit('set', {type: 'dataLoading', items:true})
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}}).then((list_response) => {
                let portfolios_list = list_response.data._items

                resolve(portfolios_list);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getAnalytics({commit, dispatch}, params){
        let url_analytics = Urls.portfolioAnalytics + "?where=" + JSON.stringify(params)
        return new Promise((resolve, reject) => {
            Vue.axios.get(url_analytics, {params: {ts: new Date().getTime()}}).then((response) => {
                let data = response.data.data
                let list = []

                if (data) {
                    let summary_row = JSON.parse(JSON.stringify(data))
                    delete summary_row.assets
                    summary_row.id    = 1
                    summary_row.level = 1
                    summary_row.show  = 1
                    summary_row.name  = 'Summary'
                    list.push(summary_row)

                    if (data.assets && data.assets.bond) {
                        let bond_row = JSON.parse(JSON.stringify(data.assets.bond))
                        delete bond_row.i
                        bond_row.id        = 11
                        bond_row.parent_id = 1
                        bond_row.level     = 2
                        bond_row.asset     = 'bond'
                        bond_row.name      = 'Fixed Income'
                        bond_row.show      = (data.assets.bond.i.length != 0) ? true : false
                        list.push(bond_row)
                        if (data.assets.bond.i) {
                            data.assets.bond.i.forEach((instrument, instrument_index) => {
                                instrument.id        = instrument.isin + instrument.pt
                                instrument.parent_id = 11
                                instrument.asset     = 'bond'
                                instrument.level     = 3

                                list.push(instrument)
                            })
                        }
                    }
                    if (data.assets && data.assets.equity) {
                        let equity_row = JSON.parse(JSON.stringify(data.assets.equity))
                        delete equity_row.i
                        equity_row.id        = 12
                        equity_row.parent_id = 1
                        equity_row.level     = 2
                        equity_row.asset     = 'equity'
                        equity_row.name      = 'Equity'
                        equity_row.show      = (data.assets.equity.i.length != 0) ? true : false
                        list.push(equity_row)
                        if (data.assets.equity.i) {
                            data.assets.equity.i.forEach((instrument, instrument_index) => {
                                instrument.id        = instrument.isin + instrument.pt
                                instrument.parent_id = 12
                                instrument.asset     = 'equity'
                                instrument.level     = 3

                                list.push(instrument)
                            })
                        }
                    }
                    if (data.assets && data.assets.cash) {
                        let cash_row = JSON.parse(JSON.stringify(data.assets.cash))
                        delete cash_row.i
                        cash_row.id        = 13
                        cash_row.parent_id = 1
                        cash_row.level     = 2
                        cash_row.asset     = 'cash'
                        cash_row.name      = 'Cash'
                        cash_row.show      = (data.assets.cash.i.length != 0) ? true : false
                        list.push(cash_row)
                        if (data.assets.cash.i) {
                            data.assets.cash.i.forEach((instrument, instrument_index) => {
                                instrument.id        = instrument.isin + instrument.pt
                                instrument.parent_id = 13
                                instrument.asset     = 'cash'
                                instrument.level     = 3

                                list.push(instrument)
                            })
                        }
                    }
                    if (data.assets && data.assets.future) {
                        let future_row = JSON.parse(JSON.stringify(data.assets.future))
                        delete future_row.i
                        future_row.id        = 14
                        future_row.parent_id = 1
                        future_row.level     = 2
                        future_row.asset     = 'future'
                        future_row.name      = 'Future'
                        future_row.show      = (data.assets.future.i.length != 0) ? true : false
                        list.push(future_row)
                        if (data.assets.future.i) {
                            data.assets.future.i.forEach((instrument, instrument_index) => {
                                instrument.id        = instrument.isin + instrument.pt
                                instrument.parent_id = 14
                                instrument.asset     = 'future'
                                instrument.level     = 3
                                instrument.show      = 1

                                list.push(instrument)
                            })
                        }
                    }
                    if (data.assets && data.assets.option) {
                        let option_row = JSON.parse(JSON.stringify(data.assets.option))
                        delete option_row.i
                        option_row.id        = 15
                        option_row.parent_id = 1
                        option_row.level     = 2
                        option_row.asset     = 'option'
                        option_row.name      = 'Option'
                        option_row.show      = (data.assets.option.i.length != 0) ? true : false
                        list.push(option_row)
                        if (data.assets.option.i) {
                            data.assets.option.i.forEach((instrument, instrument_index) => {
                                instrument.id        = instrument.isin + instrument.pt
                                instrument.parent_id = 15
                                instrument.asset     = 'option'
                                instrument.level     = 3
                                instrument.show      = 1

                                list.push(instrument)
                            })
                        }
                    }
                }

                resolve({list: list, data: data})
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                commit('set', {type: 'summaryLoading', items:false})
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    addItem({dispatch, commit, rootState}, item) {

        let props = {
            url       : url,
            name      : module_name,
            item      : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    updateItem({dispatch, commit, rootState}, item) {

        let props = {
            url        : url,
            name       : module_name,
            item       : item,
            preprocess : preprocess
        }

        return dispatch('app/update_item', props, { root: true })
    },

    deleteItem({dispatch, commit, rootState}, id){
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });
        let del_url = url_del + id

        return Vue.axios.delete(del_url).then(response => {
            commit('set', {type: 'dataLoading', items:false})
            Message({
                message: 'Portfolio was deleted successfully',
                type: 'success'
            });
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    getPortfolio({dispatch}, id) {
        let get_url = url + id
        return new Promise((resolve, reject) => {
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}})
            .then((response) => {
                resolve(response.data)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getAnalyticsFull({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.analyticsFull, params)
            .then((response) => {
                resolve(response.data)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
