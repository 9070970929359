import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui'

const state = {
    dataLoading: false
}

const actions = {

    getGrossAssets({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsGrossAssets, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getCurrencyExposure({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsCurrencyExposure, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getTopMovers({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsTopMovers, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getHighestBeta({dispatch}, params){
        return new Promise((resolve) => {
            resolve({
                _items: [
                    { name: 'HAL - Halliburton', beta: 2.84 },
                    { name: 'ET - Energy Transer', beta: 2.53 },
                    { name: 'APTV - Aptiv', beta: 2.12 },
                    { name: 'STLA - Stellantis', beta: 1.68 },
                    { name: 'BCS - Barclays', beta: 1.58 },
                    { name: 'MS - Morgan Stanley', beta: 1.56 },
                    { name: 'ETSY - Etsy', beta: 1.43 },
                    { name: 'DDAIF - Daimler', beta: 1.42 },
                    { name: 'MGA - Magna International', beta: 1.25 },
                    { name: 'AAPL - Apple Inc', beta: 0.99 },
                ]
            })
        })
    },

    getTopBetaChanges({dispatch}, params){
        return new Promise((resolve) => {
            resolve({
                _items: [
                    { name: 'MS - Morgan Stanley', beta: 0.0081 },
                    { name: 'ETSY - Etsy', beta: 0.0087 },
                    { name: 'HAL - Halliburton', beta: 0.0086 },
                    { name: 'BCS - Barclays', beta: 0.0079 },
                    { name: 'APTV - Aptiv', beta: 0.0077 },
                    { name: 'AAPL - Apple Inc', beta: 0.0053 },
                    { name: 'MGA - Magna International', beta: 0.0047 },
                    { name: 'ET - Energy Transer', beta: 0.0040 },
                    { name: 'DDAIF - Daimler', beta: 0.0039 },
                    { name: 'STLA - Stellantisc', beta: 0.0024 },
                ]
            })
        })
    },

    getTopBetaContributors({dispatch}, params){
        return new Promise((resolve) => {
            resolve({
                _items: [
                    { name: 'ET - Energy Transer', beta: 2.53, contribution: 0.0899 },
                    { name: 'APTV - Aptiv', beta: 2.12, contribution: 0.0893 },
                    { name: 'DDAIF - Daimler', beta: 1.42, contribution: 0.0880 },
                    { name: 'MS - Morgan Stanley', beta: 1.56, contribution: 0.0722 },
                    { name: 'STLA - Stellantis', beta: 1.68, contribution: 0.0610 },
                    { name: 'HAL - Halliburton', beta: 2.84, contribution: 0.0607 },
                    { name: 'MGA - Magna International', beta: 1.25, contribution: 0.0527 },
                    { name: 'BCS - Barclays', beta: 1.58, contribution: 0.0439 },
                    { name: 'ETSY - Etsy', beta: 1.43, contribution: 0.0087 },
                    { name: 'AAPL - Apple Inc', beta: 0.99, contribution: 0.0051 },
                ]
            })
        })
    },

    getBetaPortfolios({dispatch}, params){
        return new Promise((resolve) => {
            resolve({
                _items: [
                    { name: 'Portfolio 1', beta: 1.01 },
                    { name: 'Portfolio 2', beta: 0.85 },
                    { name: 'Portfolio 3', beta: 0.54 },
                    { name: 'Portfolio 4', beta: 0.35 },
                    { name: 'Portfolio 5', beta: 0.32 },
                ]
            })
        })
    },

    getPnlContributors({dispatch}, params){
        return new Promise((resolve) => {
            resolve({
                _items: [
                    { name: 'ET - Energy Transer', price_change: 9.11,  pnl: 1207007},
                    { name: 'APTV - Aptiv', price_change: 4.75,  pnl: 1088227},
                    { name: 'DDAIF - Daimler', price_change: 3.64,  pnl: 1026734},
                    { name: 'MS - Morgan Stanley', price_change: 3.22,  pnl: 1014361},
                    { name: 'STLA - Stellantis', price_change: 8.82,  pnl: 993339},
                    { name: 'HAL - Halliburton', price_change: 2.77,  pnl: 931506},
                    { name: 'MGA - Magna International', price_change: 9.02,  pnl: 868827},
                    { name: 'BCS - Barclays', price_change: 2.88,  pnl: 652248},
                    { name: 'ETSY - Etsy', price_change: 6.13,  pnl: 344896},
                    { name: 'AAPL - Apple Inc', price_change: 6.39,  pnl: 135224},
                ]
            })
        })
    },

    getPortfoliosPnl({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsPortfoliosPnl, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getDrawdown({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsDrawdown, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getCashMovements({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.widgetsCashMovements, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
