<template>
    <transition name="side-bar-overlay">
        <div id="side-bar-overlay" v-if="sidebar" @click="sidebar=false"></div>
    </transition>
</template>
<script>
export default {
    computed: {
        sidebar: {
            get() { return this.$store.state.app.sidebar; },
            set(value) { this.$store.commit('app/set', {type: 'sidebar', items:value}); },
        },
    }
}
</script>