import Vue              from 'vue'
import App              from './views/App'
import router           from './router'
import store            from './store'

import ElementUI from 'element-ui'

import 'normalize.css/normalize.css'
import '@/assets/styles/tooltips.scss'
import '@/assets/styles/main.scss'

import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale });

import moment from 'moment'
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment

import './directives/icons'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});

import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)

import resize from "vue-element-resize-detector";
Vue.use(resize)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/en';
Vue.use(DatePicker);

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

//import VueSocketIOExt from 'vue-socket.io-extended';
//import { io } from 'socket.io-client';

//const notificationSocket = io('ws://127.0.0.1:5000', {path: '/notifications/'});
//const notificationSocket = io('ws://127.0.0.1:5000');

//Vue.use(VueSocketIOExt, notificationSocket, { store });

import numeral from 'numeral';
import numFormat from '@/filters/numeral-filter';
numeral.register('locale', 'orkl', {
    delimiters: {
        thousands: ' ',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function(number) {
        var b = number % 10;
        return (~~(number % 100 / 10) === 1) ? 'th' :
            (b === 1) ? 'st' :
            (b === 2) ? 'nd' :
            (b === 3) ? 'rd' : 'th';
    },
    currency: {
        symbol: '$'
    }
});
numeral.locale('orkl');
Vue.filter('numFormat', numFormat(numeral));

import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
// import 'vxe-table/lib/index.css'
// import 'vxe-table/lib/style.css'
import enUS from 'vxe-table/lib/locale/lang/en-US'

import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)

import { date_to_string } from '@/utils'
VXETable.formats.mixin({
    formatAsPercent ({ cellValue },digits) {
        return numeral(cellValue / 100).format('0.00%');
    },
    formatDate ({ cellValue },) {
        return date_to_string(cellValue, 'DD MMM YY')
    },
    formatDateTime ({ cellValue },) {
        return date_to_string(cellValue, 'DD MMM YY HH:mm')
    },
})

Vue.use(VXETable, {
    i18n: key => XEUtils.get(enUS, key)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
