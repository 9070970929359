import numeral from 'numeral';
import moment from 'moment'
var momentBusinessDays = require("moment-business-days")

export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result

    const later = function() {
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }

    return function(...args) {
        context = this
        timestamp = +new Date()
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = func.apply(context, args)
            context = args = null
        }

        return result
    }
}

export function num_to_money(value, digits) {
    let max_digits = digits + 1
    let format_string = make_format_string(max_digits)

    if (value && /\,$/g.test(value)) {
        value = value.replace( /\,/g, '.' )
    }
    if (value === '' || value === undefined)
        return value

    if (/\.$/g.test(value) || /\.0$/g.test(value)) {
        value = value.replace( /^([^.]*\.)(.*)$/, function ( a, b, c ) {
            return b + c.replace( /\./g, '' );
        });
        return value
    }
    if (/0$/g.test(value) && /\./g.test(value)) {
        let decimals = count_decimals(value)
        let filled_decimals = decimals + 1
        let rest_decimals = max_digits - decimals
        let format = '0,0.'
        format += Array(filled_decimals).join("0") + '[' + Array(rest_decimals).join("0") + ']'
        return numeral(value).format(format);
    }
    return numeral(value).format(format_string, Math.round);
}

export function make_format_string(digits) {
    let string = '0,0[.][' + Array(digits).join("0") + ']';
    return string
}

export function count_decimals(value) {
    if (!value) return 0;
    if (Math.floor(value) === Number(value)) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function money_to_num(value) {
    if (value === '' || value === undefined)
        return value
    return numeral(value).value()
}

export function date_shortcut(shortcut) {
    let text = undefined
    let date = undefined
    if (shortcut === 'yd') {
        text = 'Yesterday'
        date = moment().add({days:-1}).toDate();
    }
    else if (shortcut === 'fdcw') {
        text = 'First Day of current week'
        date = moment().startOf('isoWeek').toDate();
    }
    else if (shortcut === 'fdcm') {
        text = 'First Day of current month'
        date = moment().startOf('month').toDate();
    }
    else if (shortcut === 'fdcq') {
        text = 'First Day of current quarter'
        date = moment().startOf('quarter').toDate();
    }
    else if (shortcut === 'fdcy') {
        text = 'First Day of current year'
        date = moment().startOf('year').toDate();
    }

    else if (shortcut === 'fbdcw') {
        text = 'First Business Day of current week'
        let last_d_prev_w = moment().add({weeks:-1}).endOf('week')
        date = momentBusinessDays(last_d_prev_w).nextBusinessDay()._d
    }
    else if (shortcut === 'fbdcm') {
        text = 'First Business Day of current month'
        let last_d_prev_m = moment().add({month:-1}).endOf('month')
        date = momentBusinessDays(last_d_prev_m).nextBusinessDay()._d
    }
    else if (shortcut === 'fbdcq') {
        text = 'First Business Day of current quarter'
        let last_d_prev_q = moment().add({quarter:-1}).endOf('quarter')
        date = momentBusinessDays(last_d_prev_q).nextBusinessDay()._d
    }
    else if (shortcut === 'fbdcy') {
        text = 'First Business Day of current year'
        let last_d_prev_y = moment().add({year:-1}).endOf('year')
        date = momentBusinessDays(last_d_prev_y).nextBusinessDay()._d
    }

    else if (shortcut === 'fbpw') {
        text = 'First Day of previous week'
        date = moment().add({weeks:-1}).startOf('isoWeek').toDate()
    }
    else if (shortcut === 'fbpm') {
        text = 'First Day of previous month'
        date = moment().add({month:-1}).startOf('month').toDate()
    }
    else if (shortcut === 'fbpq') {
        text = 'First Day of previous quarter'
        date = moment().add({quarter:-1}).startOf('quarter').toDate()
    }
    else if (shortcut === 'fbpy') {
        text = 'First Day of previous year'
        date = moment().add({year:-1}).startOf('year').toDate()
    }

    else if (shortcut === 'fbdpw') {
        text = 'First Business Day of previous week'
        let last_d_prev_w = moment().add({weeks:-2}).endOf('week')
        date = momentBusinessDays(last_d_prev_w).nextBusinessDay()._d
    } 
    else if (shortcut === 'fbdpm') {
        text = 'First Business Day of previous month'
        let last_d_prev_m = moment().add({month:-2}).endOf('month')
        date = momentBusinessDays(last_d_prev_m).nextBusinessDay()._d
    }
    else if (shortcut === 'fbdpq') {
        text = 'First Business Day of previous quarter'
        let last_d_prev_q = moment().add({quarter:-2}).endOf('quarter')
        date = momentBusinessDays(last_d_prev_q).nextBusinessDay()._d
    }
    else if (shortcut === 'fbdpy') {
        text = 'First Business Day of previous year'
        let last_d_prev_y = moment().add({year:-2}).endOf('year')
        date = momentBusinessDays(last_d_prev_y).nextBusinessDay()._d
    }

    else if (shortcut === 'ldcw') {
        text = 'Last Day of current week'
        date = moment().endOf('isoWeek').toDate();
    }
    else if (shortcut === 'ldcm') {
        text = 'Last Day of current month'
        date = moment().endOf('month').toDate();
    }
    else if (shortcut === 'ldcq') {
        text = 'Last Day of current quarter'
        date = moment().endOf('quarter').toDate();
    }
    else if (shortcut === 'ldcy') {
        text = 'Last Day of current year'
        date = moment().endOf('year').toDate();
    }

    else if (shortcut === 'lbdcw') {
        text = 'Last Business Day of current week'
        let first_d_next_w = moment().add({weeks:1}).startOf('week').toDate()
        date = momentBusinessDays(first_d_next_w).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdcm') {
        text = 'Last Business Day of current month'
        let first_d_next_m = moment().add({month:1}).startOf('month').toDate()
        date = momentBusinessDays(first_d_next_m).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdcq') {
        text = 'Last Business Day of current quarter'
        let first_d_next_q = moment().add({quarter:1}).startOf('quarter').toDate()
        date = momentBusinessDays(first_d_next_q).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdcy') {
        text = 'Last Business Day of current year'
        let first_d_next_y = moment().add({year:1}).startOf('year').toDate()
        date = momentBusinessDays(first_d_next_y).prevBusinessDay()._d
    }

    else if (shortcut === 'ldpw') {
        text = 'Last Day of previous week'
        date = moment().add({weeks:-1}).endOf('isoWeek').toDate();
    }
    else if (shortcut === 'ldpm') {
        text = 'Last Day of previous month'
        date = moment().add({month:-1}).endOf('month').toDate();
    }
    else if (shortcut === 'ldpq') {
        text = 'Last Day of previous quarter'
        date = moment().add({quarter:-1}).endOf('quarter').toDate();
    }
    else if (shortcut === 'ldpy') {
        text = 'Last Day of previous year'
        date = moment().add({year:-1}).endOf('year').toDate();
    }

    else if (shortcut === 'lbdpw') {
        text = 'Last Business Day of previous week'
        let first_d_cur_w = moment().startOf('week').toDate()
        date = momentBusinessDays(first_d_cur_w).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdpm') {
        text = 'Last Business Day of previous month'
        let first_d_cur_m = moment().startOf('month').toDate()
        date = momentBusinessDays(first_d_cur_m).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdpq') {
        text = 'Last Business Day of previous quarter'
        let first_d_cur_q = moment().startOf('quarter').toDate()
        date = momentBusinessDays(first_d_cur_q).prevBusinessDay()._d
    }
    else if (shortcut === 'lbdpy') {
        text = 'Last Business Day of previous year'
        let first_d_cur_y = moment().startOf('year').toDate()
        date = momentBusinessDays(first_d_cur_y).prevBusinessDay()._d
    }

    return { text: text, date: date, shortcut: shortcut }

}

export function date_to_string(val, format) {
    let moment_obj = date_to_moment(val)
    if (moment_obj)
        return moment_obj.format(format)
    return ''
}

export function date_to_moment(val) {
    if (val && typeof val === 'object' && val['$date']){
        if (typeof val['$date'] === 'string') {
            return moment(val['$date'])
        }
        else {
            return moment.unix(val['$date']/1000)
        }
    }
    else if (val) {
        return moment(val)
    }
    return ''
}
