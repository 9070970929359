<template>
	<el-dialog
        visible
        width="600px"
        modal
        :showClose="false"
        v-if="!isAuthed"
        title="Log in"
        destroy-on-close
        class="login_form">

        <el-form :model="login_form" label-position="top" :rules="rules" ref="loginForm">
            <el-form-item label="Login:" prop="username">
              <el-input v-model="login_form.username"></el-input>
            </el-form-item>
            <el-form-item label="Password:" prop="password">
              <el-input v-model="login_form.password" :type="password_input_type">
                  <el-button slot="append" type="text" @click="show_password"><svg-icon icon-class="eye" /></el-button>
              </el-input>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <div class="spacer" />
            <el-button type="primary" @click="make_login">Ok</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data () {
        return {
            login_form: {
                username: '',
                password: '',
            },
            rules: {
                username: [
                    { required: true, message: 'Enter your login', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Enter your password', trigger: 'blur' }
                ],
            },
            password_input_type: 'password'
        }
    },

	computed: {
        ...mapState({
            isAuthed : state => state.app.isAuthed,
        }),
    },

    methods: {
    	make_login() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.$store.dispatch('app/login', this.$data.login_form).then(() => {
                        this.login_form = {
                            username: '',
                            password: '',
                        }
                    })
                } else {
                    return false;
                }
            });
        },

        show_password(){
            this.password_input_type = 'text'
        },

        listen_keyup(event){
            if (event.keyCode === 13) {
                this.make_login()
            }
        }
    },

    mounted(){
        this.$nextTick(function () {
            window.addEventListener('keyup', this.listen_keyup);
        })
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.listen_keyup);
    },
}
</script>

<style scoped>
    
</style>
