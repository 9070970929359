import Vue  from 'vue'
import Urls from '@/../config/url.js'
import {io} from "socket.io-client";

const state = {
    list : [],
}

const module_name = 'notifications'
//const url = Urls.sourcesRiskFreeRates
const url = 'ws://127.0.0.1:5000/'

const actions = {
    /*connect({commit, dispatch, rootState}) {
        let props = {
            url      : url,
            name     : module_name,
        }

        //let socket = io(url, {path: '/notifications/'});
        let socket = io(url);

        socket.on('connect', (e) => {
            socket.send('get')
            console.log('notifications connected')
        });

        socket.on('connect_error', (e) => {
            //console.log(e)
            console.log('notifications connection error')
        });

        socket.on("notifications_updated", (items) => {
            console.log('notifications_updated');
            console.log(items)
            commit('set', {type: 'list', items: items})
        });
    },*/

    socket_newNotification({commit, dispatch, rootState}, notification) {
        commit('new_notification', notification);
    }

}

const mutations = {
    new_notification(state, {notification}) {
        state.list.push(notification)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
