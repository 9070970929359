import host from '@/../config/host.js'

const urls = {
    login                            : host + '/token/',
    tokenRefresh                     : host + '/refresh-token/',
    checkToken                       : host + '/ping/',

    searchGlobalLong                 : host + '/global-search-long/',
    searchGlobalShort                : host + '/global-search-short/',
    searchGlobal                     : host + '/global-search/',

    users                            : host + '/users/',
    userGroups                       : host + '/user-groups/',
    userSettings                     : host + '/user-settings/',
    updateUser                       : host + '/update-user/',

    clients                          : host + '/clients/',

    portfolio                        : host + '/portfolio/',
    portfolioDelete                  : host + '/portfolio-delete/',
    portfolioAnalytics               : host + '/portfolio-analytics/',
    portfolioSummary                 : host + '/portfolio-summary/',
    portfolioAccounts                : host + '/portfolio-accounts/',
    accountsIsinBalance              : host + '/accounts-isin-balance/',
    accountsBalance                  : host + '/accounts-balance/',

    portfolioOperations              : host + '/portfolio-operations/',
    portfolioOperationsVersions      : host + '/portfolio-operations-v/',
    portfolioOperationsTransactions  : host + '/portfolio-operations-transactions/',

    portfolioOperationsImport        : host + '/portfolio-operations-import/',
    portfolioOperationsImportCancel  : host + '/portfolio-operations-import-cancel/',
    portfolioOperationsImportConfirm : host + '/portfolio-operations-import-confirm/',

    portfolioSearchInstrumentTerm    : host + '/operation-search-instrument-term/',
    portfolioSearchInstrumentData    : host + '/operation-search-instrument-data/',

    operationsRecalculate            : host + '/operations-recalculate/',
    operationsRecalculatePortfolio   : host + '/operations-recalculate-portfolio/',

    accountingFifo                   : host + '/portfolio-accounting/',
    portfolioAssetPositions          : host + '/portfolio-asset-positions/',
    analyticsFull                    : host + '/analytics-full/',

    currency                         : host + '/currency/',
    currencyRates                    : host + '/currency_history/',

    instrumentShort                  : host + '/instrument-short/',
    instrumentFull                   : host + '/instrument-full/',
    instrumentPrices                 : host + '/instrument-prices/',
    instrumentVarmargin              : host + '/instrument-varmargin/',
    instrumentAccountMovements       : host + '/instrument-account-movements/',
    instrumentAccrualsCashIncome     : host + '/instrument-accruals-cashincome/',
    instrumentFees                   : host + '/instrument-fees/',
    instrumentCouponCalendar         : host + '/instrument-coupon-calendar/',

    searchIsinDates                  : host + '/search-isin-dates/',
    userFilters                      : host + '/user-filters/',
    searchIsinCount                  : host + '/asset-count/',
    searchIsinResult                 : host + '/asset-query/',
    searchIsinResultHistory          : host + '/asset-history/',

    userReports                      : host + '/user-reports/',
    userReportsFavorites             : host + '/user_reports_favorites',

    dictionaryAssetCategory          : host + '/asset-category/',
    dictionaryCounterparties         : host + '/counterparties/',
    dictionaryCustodians             : host + '/custodians/',
    dictionaryHolidays               : host + '/dict-holidays/',
    dictionaryRatingMapping          : host + '/dict-rating-mapping/',
    
    sourcesBenchmarksCodes     : host + '/benchmark/',
    sourcesBenchmarksFields    : host + '/benchmark_fields/',
    sourcesRiskFreeRatesCodes  : host + '/rfrate/',
    sourcesRiskFreeRatesFields : host + '/rfrate_fields/',
    sourcesBondIsin            : host + '/bond_isin/',
    sourcesBondFields          : host + '/bond_fields/',
    sourcesEquityIsin          : host + '/equity_isin/',
    sourcesEquityFields        : host + '/equity_fields/',
    sourcesFuturesCodes        : host + '/future_code/',
    sourcesFuturesFields       : host + '/future_fields/',
    sourcesOptionsCodes        : host + '/option_code/',
    sourcesOptionsFields       : host + '/option_fields/',
    sourcesCurrencyFields      : host + '/currency_fields/',

    // sourcesFields                    : host + '/fields/',
    // sourcesIsinUpload                : host + '/isin-upload/',
    // sourcesIsinDownload              : host + '/isin-download/',
    // sourcesIsinFieldsView            : host + '/isin-fields-view/',

    instrumentCurrency               : host + '/instrument-currency/',
    instrumentBond                   : host + '/instrument-bond/',
    instrumentEquity                 : host + '/instrument-equity/',
    instrumentOption                 : host + '/instrument-option/',
    instrumentFuture                 : host + '/instrument-future/',
    
    referenceDates                   : host + '/script-dates/',

    widgetsGrossAssets      : host + '/widgets-gross-assets',
    widgetsTopMovers        : host + '/widgets-top-movers',
    widgetsCurrencyExposure : host + '/widgets-currency-exposure',
    widgetsPortfoliosPnl    : host + '/widgets-portfolios-pnl',
    widgetsDrawdown         : host + '/widgets-drawdown',
    widgetsCashMovements    : host + '/widgets-cash-movements',

    reportPositions         : host + '/reports/positions/',
    reportPnl               : host + '/reports/pnl/',
}

export default urls
