<template>
	<div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h3>Page Not Found</h3>
				<h1><span>4</span><span>0</span><span>4</span></h1>
			</div>
			<h2>requested page not found</h2>
		</div>
	</div>
</template>

<script>
export default {

}
</script>
