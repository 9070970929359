import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui'

const state = {}


const actions = {

    getInstrumentShort({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentShort, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getInstrumentFull({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentFull, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getInstrumentPrices({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentPrices, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getVarmargin({dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentVarmargin, { params: params })
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getAccountingFifo({commit, dispatch}, params){
        let get_url = Urls.accountingFifo + "?where=" + JSON.stringify(params)
        return new Promise((resolve, reject) => {
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}}).then((response) => {
                let body = response.data._items
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getAccountMovemements({commit, dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentAccountMovements, {params: params}).then((response) => {
                let body = response.data
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getAccrualsCashIncome({commit, dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentAccrualsCashIncome, {params: params}).then((response) => {
                let body = response.data
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getFees({commit, dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentFees, {params: params}).then((response) => {
                let body = response.data
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getBondCouponCalendar({commit, dispatch}, params){
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.instrumentCouponCalendar, {params: params}).then((response) => {
                let body = response.data
                resolve(body)
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
