import Vue            from 'vue'
import Router         from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

export const routerMap = [
    {
        path     : '/',
        redirect : '/home',
        hidden   : true,
    },
    {
        path : '/home',
        name : 'home',
        meta: {
            title   : 'Home',
            icon    : 'home',
            sidebar : true,
            comp    : 'Home',
        }
    // },{
    //     path : '/strategies',
    //     name : 'strategies',
    //     meta: {
    //         title   : 'Strategies',
    //         icon    : 'strategies',
    //         sidebar : true,
    //         roles   : ['get-clients'],
    //     }
    },{
        path: '/reporting/',
        name : 'reporting',
        meta: {
            title     : 'Reporting',
            icon      : 'reporting',
            sidebar   : true,
            comp      : 'Reporting',
            comp_type : 'reporting',
        }
    },{
        path: '/reporting/:category',
        name : 'reporting_result',
        props : true,
        meta: {
            title          : 'Reporting',
            icon           : 'reporting',
            sidebar        : false,
            comp           : 'Reporting/ReportResult',
            tab_with_props : true,
            main_path      : 'reporting',
        }
    },{
        path: '/searchisin/',
        name : 'search_isin',
        props : true,
        meta: {
            title          : 'Search ISIN',
            icon           : 'search',
            sidebar        : true,
            comp           : 'SearchISIN',
            roles          : ['get-isin', 'get-isin-fields'],
            main_path      : 'searchisin',
            tab_with_props : true,
        }
    },{
        path: '/searchisin/:p',
        name : 'search_isin_result',
        props : true,
        meta: {
            title          : 'Search ISIN',
            icon           : 'search',
            sidebar        : false,
            comp           : 'SearchISIN',
            roles          : ['get-isin', 'get-isin-fields'],
            main_path      : 'searchisin',
            tab_with_props : true,
        }
    },

    {
        path: '/clients/',
        name : 'clients',
        meta: {
            title     : 'Clients',
            roles     : ['get-clients'],
            icon      : 'users',
            sidebar   : true,
            pagetitle : "Clients",
            comp      : 'Clients',
            comp_type : 'table_layout',
        }
    },

    {
        path: '/library',
        name : 'library',
        meta: {
            title: 'Libraries',
            roles: ['get-isin', 'get-isin-fields', 'get-dict', 'get-portf-settings', 'get-currency', 'get-clients'],
            icon: 'notebook',
            sidebar : true,
        }
    },
    // {
    //     path: '/library/clients',
    //     name : 'library_clients',
    //     meta: {
    //         title  : "Clients",
    //         roles  : ['get-clients'],
    //         parent : 'library',
    //         sidebar : true,
    //         pagetitle : "Clients",
    //         comp      : 'Clients',
    //         comp_type : 'table_layout',
    //     }
    // },
    {
        path: '/library/instruments',
        name : 'library_instruments',
        meta: {
            title  : "Instruments",
            roles  : ['get-isin', 'get-currency'],
            parent : 'library',
            sidebar : true,
        }
    },
    {
        path: '/library/instruments/currencies',
        name : 'instruments_currencies',
        meta: {
            title     : "Currency",
            roles     : ['get-currency'],
            parent    : 'library_instruments',
            sidebar   : true,
            pagetitle : "Currency",
            comp      : 'Instruments/Currency/List',
            comp_type : 'table_layout',
        }
    },
    {
        path: '/library/instruments/currency/:isin',
        name : 'instruments_currency_isin',
        props : true,
        meta: {
            roles          : ['get-isin'],
            sidebar        : false,
            comp           : 'Instruments/Currency/Page',
            tab_with_props : true,
            main_path      : 'library/instruments/currency'
        }
    },
    {
        path: '/library/instruments/bond',
        name : 'instruments_bond',
        meta: {
            title     : "Bonds",
            roles     : ['get-isin'],
            parent    : 'library_instruments',
            sidebar   : true,
            pagetitle : "Bonds",
            comp      : 'Instruments/Bond/List',
            comp_type : 'table_layout',
        }
    },
    {
        path: '/library/instruments/bond/:isin',
        name : 'instruments_bond_isin',
        props : true,
        meta: {
            roles          : ['get-isin'],
            sidebar        : false,
            comp           : 'Instruments/Bond/Page',
            tab_with_props : true,
            main_path      : 'library/instruments/bond'
        }
    },
    {
        path: '/library/instruments/equities',
        name : 'instruments_equities',
        meta: {
            title     : "Equities",
            roles     : ['get-isin'],
            parent    : 'library_instruments',
            sidebar   : true,
            pagetitle : "Equities",
            comp      : 'Instruments/Equity/List',
            comp_type : 'table_layout',
        }
    },{
        path: '/library/instruments/equity/:isin',
        name : 'instruments_equity_isin',
        props : true,
        meta: {
            roles          : ['get-isin'],
            sidebar        : false,
            comp           : 'Instruments/Equity/Page',
            tab_with_props : true,
            main_path      : 'library/instruments/equity'
        }
    },
    {
        path: '/library/instruments/options',
        name : 'instruments_options',
        meta: {
            title     : "Options",
            roles     : ['get-isin'],
            parent    : 'library_instruments',
            sidebar   : true,
            pagetitle : "Options",
            comp      : 'Instruments/Option/List',
            comp_type : 'table_layout',
        }
    },{
        path: '/library/instruments/option/:isin',
        name : 'instruments_options_isin',
        props : true,
        meta: {
            roles          : ['get-isin'],
            sidebar        : false,
            comp           : 'Instruments/Option/Page',
            tab_with_props : true,
            main_path      : 'library/instruments/option'
        }
    },
    {
        path: '/library/instruments/futures',
        name : 'instruments_futures',
        meta: {
            title     : "Futures",
            roles     : ['get-isin'],
            parent    : 'library_instruments',
            sidebar   : true,
            pagetitle : "Futures",
            comp      : 'Instruments/Future/List',
            comp_type : 'table_layout',
        }
    },{
        path: '/library/instruments/future/:isin',
        name : 'instruments_futures_isin',
        props : true,
        meta: {
            roles          : ['get-isin'],
            sidebar        : false,
            comp           : 'Instruments/Future/Page',
            tab_with_props : true,
            main_path      : 'library/instruments/future'
        }
    },

    {
        path: '/library/dictionaries',
        name : 'library_dictionaries',
        meta: {
            title  : "Dictionaries",
            roles  : ['get-isin', 'get-isin-fields', 'get-dict', 'get-portf-settings'],
            parent : 'library',
            sidebar : true,
        }
    },
    {
        path: '/library/dictionaries/custodians',
        name : 'dictionaries_custodians',
        meta: {
            title     : "Custodians",
            roles     : ['get-portf-settings'],
            parent    : 'library_dictionaries',
            sidebar   : true,
            comp      : 'Dictionaries/Custodians',
            comp_type : 'table_layout',
            pagetitle : "Custodians",
        }
    },
    {
        path: '/library/dictionaries/counterparties',
        name : 'dictionaries_counterparties',
        meta: {
            title     : "Counterparties",
            roles     : ['get-portf-settings'],
            parent    : 'library_dictionaries',
            sidebar   : true,
            comp      : 'Dictionaries/Counterparties',
            comp_type : 'table_layout',
            pagetitle : "Counterparties",
        }
    },
    {
        path: '/library/dictionaries/asset-category',
        name : 'dictionaries_asset_category',
        meta: {
            title     : "Assets Categories",
            roles     : ['get-portf-settings'],
            parent    : 'library_dictionaries',
            sidebar   : true,
            comp      : 'Dictionaries/AssetCategory',
            comp_type : 'table_layout',
            pagetitle : "Assets Categories",
        }
    },
    {
        path: '/library/dictionaries/holidays',
        name : 'dictionaries_holidays',
        meta: {
            title     : "Holidays",
            roles     : ['get-dict'],
            parent    : 'library_dictionaries',
            sidebar   : true,
            comp      : 'Dictionaries/Holidays',
            comp_type : 'table_layout',
            pagetitle : "Holidays",
        }
    },
    // {
    //     path: '/library/dictionaries/rating_mapping',
    //     name : 'dictionaries_rating_mapping',
    //     meta: {
    //         title     : "Rating Mappings",
    //         roles     : ['get-dict'],
    //         parent    : 'library_dictionaries',
    //         sidebar   : true,
    //         comp      : 'Dictionaries/RatingMapping',
    //         comp_type : 'table_layout',
    //         pagetitle : "Rating Mappings",
    //     }
    // },
    {
        path: '/library/sources',
        name : 'library_sources',
        meta: {
            title       : "Source Data",
            roles       : ['get-isin', 'get-isin-fields'],
            parent      : 'library',
            sidebar : true,
        }
    },

    {
        path: '/library/sources/currencycodes',
        name : 'sources_currencycodes',
        meta: {
            title     : "Currencies Codes",
            roles     : ['get-isin'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/CurrencyCodes',
            comp_type : 'table_layout',
            pagetitle : "Currencies Codes for Data Upload",
        }
    },
    {
        path: '/library/sources/currencyfields',
        name : 'currencyfields',
        meta: {
            title     : "Currencies Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/CurrencyFields',
            comp_type : 'table_layout',
            pagetitle : "Currencies Data Fields",
        }
    },

    {
        path: '/library/sources/bondisin',
        name : 'sources_bondisin',
        meta: {
            title     : "Bonds ISINs",
            roles     : ['get-isin'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/BondIsins',
            comp_type : 'table_layout',
            pagetitle : "Bonds ISINs for Data Upload",
        }
    },
    {
        path: '/library/sources/bondfields',
        name : 'sources_bondfields',
        meta: {
            title     : "Bonds Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/BondFields',
            comp_type : 'table_layout',
            pagetitle : "Bonds Data Fields",
        }
    },

    {
        path: '/library/sources/equityisin',
        name : 'sources_equityisin',
        meta: {
            title     : "Equities ISINs",
            roles     : ['get-isin'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/EquityIsins',
            comp_type : 'table_layout',
            pagetitle : "Equities ISINs for Data Upload",
        }
    },
    {
        path: '/library/sources/equityfields',
        name : 'sources_equityfields',
        meta: {
            title     : "Equities Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/EquityFields',
            comp_type : 'table_layout',
            pagetitle : "Equities Data Fields",
        }
    },

    {
        path: '/library/sources/futurecode',
        name : 'sources_futurecode',
        meta: {
            title     : "Futures Codes",
            roles     : ['get-isin'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/FutureCodes',
            comp_type : 'table_layout',
            pagetitle : "Futures DS Codes for Data Upload",
        }
    },
    {
        path: '/library/sources/futurefields',
        name : 'sources_futurefields',
        meta: {
            title     : "Futures Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/FutureFields',
            comp_type : 'table_layout',
            pagetitle : "Futures Data Fields",
        }
    },

    {
        path: '/library/sources/optioncode',
        name : 'sources_optioncode',
        meta: {
            title     : "Options Codes",
            roles     : ['get-isin'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/OptionCodes',
            comp_type : 'table_layout',
            pagetitle : "Options DS Codes for Data Upload",
        }
    },
    {
        path: '/library/sources/optionfields',
        name : 'sources_optionfields',
        meta: {
            title     : "Options Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/OptionFields',
            comp_type : 'table_layout',
            pagetitle : "Options Data Fields",
        }
    },

    {
        path: '/library/sources/benchmarks',
        name : 'sources_benchmarks',
        meta: {
            title     : "Benchmarks Codes",
            roles     : ['get-portf-settings'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/BenchmarksCodes',
            comp_type : 'table_layout',
            pagetitle : "Benchmarks Codes",
        }
    },
    {
        path: '/library/sources/benchmarkfields',
        name : 'sources_benchmarkfields',
        meta: {
            title     : "Benchmarks Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/BenchmarksFields',
            comp_type : 'table_layout',
            pagetitle : "Benchmarks Data Fields",
        }
    },
    {
        path: '/library/sources/riskfreerates',
        name : 'sources_riskfreerates',
        meta: {
            title     : "Risk-Free Rates Codes",
            roles     : ['get-portf-settings'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/RiskFreeRatesCodes',
            comp_type : 'table_layout',
            pagetitle : "Risk-Free Rates Codes",
        }
    },
    {
        path: '/library/sources/riskfreefields',
        name : 'sources_riskfreefields',
        meta: {
            title     : "Risk-Free Rates Data Fields",
            roles     : ['get-isin-fields'],
            parent    : 'library_sources',
            sidebar   : true,
            comp      : 'SourceData/RiskFreeRatesFields',
            comp_type : 'table_layout',
            pagetitle : "Risk-Free Rates Data Fields",
        }
    },

    {
        path: '/library/user_management',
        name : 'library_user_management',
        meta: {
            title  : 'Users',
            roles  : ['get-users'],
            parent : 'library',
            sidebar : true,
            pagetitle : "Users Management",
        }
    },
    {
        path: '/library/user_management/users',
        name : 'user_management_users',
        meta: {
            title       : 'Users',
            roles       : ['get-users'],
            parent      : 'library_user_management',
            sidebar     : true,
            comp        : 'UserManagement/Users',
            comp_type   : 'table_layout',
            pagetitle   : "Users",
            breadcrumbs : true,
        }
    },
    {
        path: '/library/user_management/groups',
        name : 'user_management_groups',
        meta: {
            title       : 'Users Groups',
            roles       : ['get-users'],
            parent      : 'library_user_management',
            sidebar     : true,
            comp        : 'UserManagement/Groups',
            comp_type   : 'table_layout',
            pagetitle   : "Users Groups",
            breadcrumbs : true,
        }
    },

    {
        path: '/library/reference',
        name : 'library_reference',
        meta: {
            title  : 'Reference',
            roles  : ['get-dict'],
            parent : 'library',
            sidebar : true,
        }
    },
    // {
    //     path: '/library/reference/dates',
    //     name : 'reference_dates',
    //     meta: {
    //         title     : 'Reuters Dates Values',
    //         parent    : 'library_reference',
    //         roles     : ['get-dict'],
    //         sidebar   : true,
    //         comp      : 'Reference/Dates',
    //         comp_type : 'table_layout',
    //         pagetitle : "Reuters Dates Values",
    //     }
    // },

    {
        path: '/settings',
        name : 'settings',
        meta: {
            title: 'Settings',
            roles: ['get-isin', 'get-isin-fields', 'get-dict', 'get-portf-settings'],
            comp: 'Settings',
            icon: 'settings',
            sidebar : true,
        }
    },

    {
        path: '/blackbox',
        name : 'blackbox',
        meta: {
            title: 'Black Box',
            comp: 'BlackBox',
            icon: 'box-solid',
            sidebar : true,
        }
    },

    {
        path: '/changelog',
        name : 'changelog',
        meta: {
            title : 'Application Changelog',
            comp  : 'Changelog',
        }
    },

    {
        path  : '/client/:clientid',
        name  : 'client',
        props : true,
        meta: {
            comp           : 'Client',
            roles          : ['get-clients'],
            main_path      : 'client',
            tab_with_props : true,
        }
    },{
        path  : '/strategy/:clientid/:strategyid?',
        name  : 'client_strategy',
        props : true,
        meta: {
            comp           : 'Client/Strategy/Page',
            roles          : ['get-clients'],
            main_path      : 'strategy',
            tab_with_props : true,
        }
    },{
        path  : '/portfolio/:clientid/:portfolioid',
        name  : 'client_portfolio',
        props : true,
        meta: {
            comp           : 'Client/Portfolio/Page',
            roles          : ['get-clients'],
            main_path      : 'portfolio',
            tab_with_props : true,
        }
    },{
        path  : '/new-operation/:clientid/:portfolioid',
        name  : 'new_operation',
        props : true,
        meta: {
            comp           : 'Client/Operation/',
            roles          : ['get-clients'],
            main_path      : 'new-operation',
            tab_with_props : true,
        }
    },{
        path  : '/operation/:clientid/:portfolioid/:operationid',
        name  : 'operation',
        props : true,
        meta: {
            comp           : 'Client/Operation/',
            roles          : ['get-clients'],
            main_path      : 'operation',
            tab_with_props : true,
        }
    },{
        path  : '/accounts/:clientid/:portfolioid',
        name  : 'accounts',
        props : true,
        meta: {
            comp           : 'Client/Portfolio/Accounts',
            roles          : ['get-clients'],
            main_path      : 'accounts',
            tab_with_props : true,
        }
    },{
        path  : '/portfolio-analytics/:clientid/:portfolioid',
        name  : 'portfolio_analytics',
        props : true,
        meta: {
            comp           : 'Client/Analytics/',
            roles          : ['get-clients'],
            main_path      : 'portfolio-analytics',
            tab_with_props : true,
        }
    },{
        path  : '/operations/:clientid/:portfolioid',
        name  : 'operations',
        props : true,
        meta: {
            comp           : 'Client/Operations/',
            roles          : ['get-clients'],
            main_path      : 'operations',
            tab_with_props : true,
        }
    },{
        path  : '/portfolio-instrument/:clientid/:portfolioid/:asset/:isin/:pt',
        name  : 'portfolio_instrument',
        props : true,
        meta: {
            comp           : 'Client/Instrument/',
            roles          : ['get-clients'],
            main_path      : 'portfolio-instrument',
            tab_with_props : true,
        }
    },{
        path  : '/accounting-calculations/:clientid/:portfolioid/:asset/:isin/:pt',
        name  : 'accounting_calculations',
        props : true,
        meta: {
            comp           : 'Client/Portfolio/AccountingFifo',
            roles          : ['get-clients'],
            main_path      : 'accounting-calculations',
            tab_with_props : true,
        }
    },

    {
        path: '/search',
        name : 'search',
        props: true,
        meta: {
            title     : "Search",
            sidebar   : false,
            comp      : 'Search',
        }
    },

    {
        path: '/404',
        name : '404',
        meta: {
            title : '404',
            comp: 'App/404'
        }
    },
    { path: '*', redirect: '/404', hidden: true }
]

const router =  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: routerMap
})

export default router
