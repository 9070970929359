import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list        : [],
    current     : {},
    totalCount  : 0,
    currentPage : 1,
    perPage     : 20,
    dataLoading : false,
    editWindow  : false,
    options     : [],
    optionsHash : {},
}

const module_name = 'dictionaryCounterparties'
const url = Urls.dictionaryCounterparties

const actions = {
    getList({commit, dispatch, rootState}) {
        let props = {
            url      : url,
            name     : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    addItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    updateItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    deleteItem({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    getOptions({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : ['name']
        }

        return dispatch('app/get_options', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
