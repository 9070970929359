import Vue  from 'vue'
import Urls from '@/../config/url.js'

const available_themes = [
    'light',
    'dark'
]

let current_theme = 'light'
if(localStorage.orkl_theme){
    current_theme = localStorage.orkl_theme
}

const state = {
    current_theme: current_theme,
}

const actions = {
    toggle_theme({commit, rootState}){
        const current_theme = rootState.current_theme

        if(current_theme === 'light') {
            commit('set', {type: 'current_theme', items: 'dark'})
            localStorage.orkl_theme = 'dark'
        } else if (current_theme === 'dark') {
            commit('set', {type: 'current_theme', items: 'light'})
            localStorage.orkl_theme = 'light'
        }
    },
    set_theme({commit}, params) {
        commit('set', {type: 'current_theme', items: params.theme})
        localStorage.orkl_theme = params.theme
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
