<template>
    <vxe-modal
        v-model="showForm"
        :title="'Edit Profile'"
        :width="modalWidth"
        resize
        destroy-on-close
        :dblclickZoom="false"
        @close="$emit('close-form')"
        size="mini"
        className="tl-modal">

        <el-form label-position="top" :label-width="'140px'" :model="formData" size="small" :rules="formRules" ref="Editor" autocomplete="off" class="tl-form">
            <div class="tl-form-body">
                <el-form-item v-if="formErrors.length">
                    <el-alert
                        v-for="(error, index) in formErrors"
                        :key="index"
                        :title="error"
                        type="error">
                    </el-alert>
                </el-form-item>
                <el-form-item label="First Name" prop="firstname">
                    <el-input v-model="formData.firstname" maxlength="70" minlength="3" />
                </el-form-item>
                <el-form-item label="Last Name" prop="lastname">
                    <el-input v-model="formData.lastname" maxlength="160" minlength="3" />
                </el-form-item>
                <el-form-item label="Current password" prop="current_password">
                    <el-input v-model="formData.current_password" maxlength="50" minlength="5" show-password/>
                </el-form-item>
                <el-form-item label="New password" prop="new_password">
                    <el-input v-model="formData.new_password" maxlength="50" minlength="5" show-password/>
                </el-form-item>
                <el-form-item label="Confirm Password" prop="confirm_password">
                    <el-input v-model="formData.confirm_password" maxlength="50" minlength="5" show-password/>
                </el-form-item>
            </div>

            <div class="tl-form-buttons">
                <div class="spacer" />
                <el-button @click="$emit('close-form')">Cancel</el-button>
                <el-button type="primary" @click="submitEvent">Save</el-button>
            </div>
        </el-form>
    </vxe-modal>
</template>
<script>
import {mapState} from "vuex";
import $ from 'jquery'
import Vue from "vue";
import Urls from "@/../config/url";
import {Message} from "element-ui";

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data(){
        return {
            formData: {},
            module: 'users',
            showForm: this.show,
            fields: [
                '_id',
                //'_etag',
                //'_updated',
                //'_deleted',
                //'_created',
                'firstname',
                'lastname',
                'new_password',
                'username',
                'current_password',
                'confirm_password'
            ],
            formRules: {},
            formErrors: []
        }
    },
    computed: {
        modalWidth(){
            let w
            if ($(window).width() <= 600)
                w = $(window).width()
            else if ($(window).width() > 600 && $(window).width() <= 800)
                w = $(window).width() * 0.8
            else
                w = $(window).width() * 0.5
            return w
        },
        ...mapState({
            user : state => state.app.user,
            user_groups : state => state.userGroups.options,
            history : state => state.app.history,
            /*notifications: function(state){
                let notifications = state.notifications.list
                this.notifications_list = notifications
                return notifications
            }*/
        }),
    },
    methods: {
        submitEvent(){
            let $this = this;
            $this.$refs.Editor.validate((valid) => {

                if (valid) {

                    $this.$refs.Editor.clearValidate();
                    $this.formErrors = [];

                    if ($this.formData._id){

                        let reqparams = {
                            headers: {
                                'If-Match': $this.formData._etag,
                            }
                        };

                        let data = JSON.parse(JSON.stringify($this.formData))

                        for(let prop in data)
                            if(!this.fields.includes(prop))
                                delete data[prop]

                        delete data['confirm_password']
                        if(!data['new_password']) delete data['new_password']

                        data._id = data._id.$oid

                        $this.$store.commit('users/set', {type: 'dataLoading', items: true}, { root: true });

                        Vue.axios.patch(Urls.updateUser, data, reqparams)
                        .then(() => {
                            $this.$store.dispatch('app/get_list', {name: $this.module, url: Urls.users});
                            $this.$store.dispatch('app/getCurrentUser');

                            $this.$store.commit('users/set', {type: 'dataLoading', items: false}, { root: true });

                            Message({
                                message: 'Edited Successfully',
                                type: 'success'
                            })

                            $this.$emit('close-form')

                            $this.formData = {}
                            $this.formErrors = []
                        })
                        .catch((error) => {
                            $this.$store.commit(
                                'users/set',
                                {type: 'dataLoading', items: false},
                                { root: true }
                            );

                            if(error.response && error.response.data.message)
                                $this.formErrors.push(error.response.data.message)

                            Message({
                                message: 'Something went wrong',
                                type: 'error'
                            })

                            $this.init
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        initFormRules(){
            let rules = {
                firstname: [
                    { min: 3, message: 'this field should have min 3 characters', trigger: 'blur' },
                    { max: 70, message: 'this field should have max 70 characters', trigger: 'blur' },
                ],
                lastname: [
                    { min: 3, message: 'this field should have min 3 characters', trigger: 'blur' },
                    { max: 160, message: 'this field should have max 160 characters', trigger: 'blur' },
                ],
                current_password: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                    { min: 3, message: 'this field should have min 3 character', trigger: 'blur' },
                    { max: 50, message: 'this field should have max 50 characters', trigger: 'blur' },
                ],
                new_password: [ { validator: this.validatePassword, trigger: 'blur' } ],
                confirm_password: [ { validator: this.validatePassword, trigger: 'blur' } ]
            }

            this.formRules = rules
        },
        validatePassword(rule, value, callback){

            let newPassword = this.formData.new_password
            let confirmPassword = this.formData.confirm_password

            if(newPassword.length || confirmPassword.length) {
                if(
                    confirmPassword.length < 3
                    || newPassword.length < 3
                ) {
                    callback(new Error('this field should have min 3 character'))
                }

                if(
                    confirmPassword.length > 50
                    || newPassword.length > 50
                ) {
                    callback(new Error('this field should have max 50 characters'))
                }

                if(confirmPassword != newPassword) {
                    callback(new Error('fields "New Password" and "Confirm Password" must be equal'))
                }

                callback()
            }

            callback()
        },
    },

    watch: {
        show(val){
            if (this.$refs.Editor) this.$refs.Editor.clearValidate();
            if (val) {
                this.initFormRules();
                this.formData = {};
                if (this.user._id) {
                    this.formData = Object.assign(
                        {
                            current_password: '',
                            new_password: '',
                            confirm_password: ''
                        },
                        JSON.parse(JSON.stringify(this.user))
                    );
                }
            } else {
                this.formData = {}
            }

            this.showForm = val
        },
    }
}
</script>