import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui'

const module_name = 'portfolioOperations'
const url = Urls.portfolioOperations

const assets_list = [
    {value: 'bond',   label: 'Fixed Income', disabled: true},
    {value: 'equity', label: 'Equity', disabled: true},
    {value: 'cash',   label: 'Cash', disabled: false},
    {value: 'future',   label: 'Future', disabled: true},
]

const getters = {
    assets_list: state => {
        return assets_list
    },
}

const state = {}

const actions = {
    getList({dispatch}, params) {
        let get_url = url + "?where=" + JSON.stringify(params)
        return new Promise((resolve, reject) => {
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}})
            .then((response) => {
                resolve(response.data._items);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getItem({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + params.client_id + '/' + params.id, {params: {ts: new Date().getTime()}})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    recalculate({dispatch, commit, rootState}, params) {
        commit('set', {type: 'dataLoading', items:true})
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });

        return Vue.axios.post(Urls.operationsRecalculate, params).then(response => {
            commit('set', {type: 'dataLoading', items:false})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    recalculate_portfolio({dispatch, commit, rootState}, params) {
        commit('set', {type: 'dataLoading', items:true})
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });

        return Vue.axios.post(Urls.operationsRecalculatePortfolio, params).then(response => {
            commit('set', {type: 'dataLoading', items:false})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    getVersionsList({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.portfolioOperationsVersions, {params: params})
            .then((response) => {
                resolve(response.data._items);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getTransactionsList({dispatch}, params) {
        params.ts = new Date().getTime()
        return new Promise((resolve, reject) => {
            Vue.axios.get(Urls.portfolioOperationsTransactions, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    addItem({dispatch, commit, rootState}, item) {
        commit('set', {type: 'dataLoading', items:true})
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });

        return Vue.axios.post(url, item).then(response => {
            commit('set', {type: 'dataLoading', items:false})
            Message({
                message: 'Operation was added successfully',
                type: 'success'
            });
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    updateItem({dispatch, commit, rootState}, item) {
        commit('set', {type: 'dataLoading', items:true})
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });

        return Vue.axios.patch(url, item).then(response => {
            if (response.data.no_changes)
                Message({
                    message: 'No changes saved as no changes were done',
                    type: 'warning'
                });
            else
                Message({
                    message: 'Operation was edited successfully',
                    type: 'success'
                });
            commit('set', {type: 'dataLoading', items:false})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    deleteItem({dispatch, commit, rootState}, data){
        commit('set', {type: 'dataLoading', items:true})
        commit('app/set', {type: 'serverError', items: undefined}, { root: true });

        let del_url = url + data.client_id + '/' + data.id + "?reason=" + data.reason

        return Vue.axios.delete(del_url).then(response => {
            commit('set', {type: 'dataLoading', items:false})
            Message({
                message: 'Operation was deleted successfully',
                type: 'success'
            });
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
            commit('set', {type: 'dataLoading', items:false})
        })
    },

    cancelImport({dispatch}, params) {
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.portfolioOperationsImportCancel, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    confirmImport({dispatch}, params) {
        return new Promise((resolve, reject) => {
            Vue.axios.post(Urls.portfolioOperationsImportConfirm, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
