import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list        : [],
    dataLoading : false,
    current     : {},
    options     : [],
}

const module_name = 'userReports'
const url = Urls.userReports

const actions = {
    getList({commit, dispatch, rootState}, params) {
        let get_url = url + "?where=" + JSON.stringify(params)

        let props = {
            url     : get_url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    addItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    updateItem({dispatch, commit, rootState}, item) {

        let props = {
            url      : url,
            name     : module_name,
            item     : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    deleteItem({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
