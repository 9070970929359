<template>
    <div id="widgets-bar" v-if="widgetsbar">
        <!-- <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110">
        <img src="@/assets/images/Profit.jpg" width="110"> -->
        <img src="@/assets/images/Call us.jpg" width="110">
        <img src="@/assets/images/Clock.jpg" width="110">
        <!-- <img src="@/assets/images/CNN.jpg" width="110"> -->
        <img src="@/assets/images/Contacts Button.jpg" width="110">
        <img src="@/assets/images/E-mail.jpg" width="110">
        <img src="@/assets/images/Euro-S.jpg" width="110">
        <!-- <img src="@/assets/images/Help button.jpg" width="110"> -->
        <img src="@/assets/images/NYSE.jpg" width="110">
        <img src="@/assets/images/Tokyo Stock Exchange.jpg" width="110">
        <img src="@/assets/images/Weather report.jpeg" width="110">
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            widgetsbar   : state => state.app.widgetsbar,
        }),
    },
}
</script>
