<template>
    <div>
        <div v-if="isAuthed">
            <div id="app" class="dark">
                <el-tabs v-model="active_tab" type="card" id="tabs-bar" @tab-click="tab_select" @tab-remove="tab_remove" ref="TabsBar">
                    <el-tab-pane v-for="(item, index) in tabs"
                            :key="item.name"
                            :name="item.name"
                            :closable="item.closable"
                            ref="tabs"
                            :path="item.path"
                            :query="item.query"
                            :content_type="item.content_type"
                            :tab_with_props="item.tab_with_props"
                            :route_name="item.route_name">
                        <div slot="label" class="tabs-bar-label">
                            <svg-icon
                                :icon-class="item.icon"
                                v-if="item.icon"
                                v-tooltip="{
                                  content: item.title,
                                  offset: -6,
                                  classes: ['tabs-bar-label__tooltip'],
                                }" />
                            <span class="tabs-bar-label__text"
                                v-else
                                v-tooltip="{
                                  content: item.title,
                                  offset: -6,
                                  classes: ['tabs-bar-label__tooltip'],
                                }"
                            >
                              {{item.title}}
                            </span>
                        </div>

                        <TitleBar 
                            @selected-search-result="selected_search_result"
                            @selected-history-tab="tab_history" />

                        <div class="flex">
                            <div class="app-content spacer">
                                <keep-alive>
                                    <component 
                                        :is="item.content" 
                                        :ref="item.name"
                                        @tab-title-change="change_tab_title(item.name, $event)" 
                                        @tab-query-change="change_tab_query(item.name, $event)" 
                                        @tab-close="tab_remove(item.name)" 
                                        @update-portfolio-data="update_portfolio_data"
                                        v-bind="item.props"/>
                                </keep-alive>
                            </div>
                            <WidgetsBar />
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <Logo ref="Logo" />
            </div>
            <SideBar />
            <SideBarOverlay />
            <Settings />
        </div>
        <Login v-else />
    </div>
</template>

<script>

import { mapState } from 'vuex'

import Login from './App/Login.vue'
import Page404 from './App/404'

import TitleBar from './App/TitleBar'
import SideBar from './App/SideBar'
import SideBarOverlay from "./App/SideBarOverlay";
import WidgetsBar from './App/WidgetsBar'
import Logo from './App/Logo'
import Settings from '@/views/Settings'

export default {
    components: {
        Login,
        Page404,

        TitleBar,
        SideBar,
        SideBarOverlay,
        WidgetsBar,
        Logo,
        Settings,
    },
    computed: {
        ...mapState({
            isAuthed        : state => state.app.isAuthed,
            routes          : state => state.app.routes_plain,
            user            : state => state.app.user,
            serverError     : state => state.app.serverError,
            serverErrorText : state => state.app.serverErrorText,
        }),
        sidebar: {
            get() { return this.$store.state.app.sidebar; },
            set(value) { this.$store.commit('app/set', {type: 'sidebar', items:value}); },
        },

        history: {
            get() { return this.$store.state.app.history; },
            set(value) { this.$store.commit('app/set', {type: 'history', items:value}); },
        },
    },

    data(){
        return {
            active_tab: 'home',

            tabs: [
                {
                    name     : 'home',
                    closable : false,
                    content  : () => import('@/views/Home'),
                    icon     : 'home',
                    path     : '/home',
                    title    : 'Home'
                },
            ],
        }
    },

    methods: {
        tab_select(tab){
            this.$router.push({path: tab.$attrs.path, query: tab.$attrs.query || {}})
            if (tab.$attrs.content_type && tab.$attrs.content_type === 'table_layout')
                this.$refs[tab.name][0].rerender_dictionary();
            else if (tab.$attrs.content_type && tab.$attrs.content_type === 'reporting')
                this.$refs[tab.name][0].rerender_tab();
            else if (tab.name == 'home')
                this.$refs[tab.name][0].rerender_tab();

        },
        tab_remove(tab_name){
            let index = this.tabs.findIndex(x => x.name === tab_name)
            let prev_tab = this.tabs[index-1]
            this.tabs.splice(index, 1);
            if (this.active_tab !== prev_tab.name) {
                this.active_tab = prev_tab.name
                if (prev_tab.query) 
                    this.$router.push({path: prev_tab.path, query: prev_tab.query});
                else
                    this.$router.push(prev_tab.path);
            }
        },

        tab_add(route) {
            let can_add = this.check_route_access(route)
            if (can_add) {
                let tab = {
                    closable : true,
                }
                if (route.meta.comp) tab.content = () => import('@/views/' + route.meta.comp);
                if (route.meta.comp_type) tab.content_type = route.meta.comp_type;

                if (route.meta && route.meta.tab_with_props) {
                    let name_postfix = Object.values(route.params).filter(n => n).join('-')
                    tab.name = route.name + name_postfix
                    if (route.name === 'reporting_result')
                        tab.name += route.query.p;
                    
                    let tab_path_postfix = '';
                    if (route.name === 'search_isin_result')
                        tab_path_postfix = encodeURIComponent(Object.values(route.params).filter(n => n).join('/'));
                    else
                        tab_path_postfix = Object.values(route.params).filter(n => n).join('/');

                    //let tab_path_postfix = Object.values(route.params).filter(n => n).map(n => encodeURIComponent(n)).join('/')

                    tab.path = '/' + route.meta.main_path + '/' + tab_path_postfix

                    tab.title = ''
                    tab.tab_with_props = true
                    tab.route_name = route.name
                }
                else {
                    tab.title = route.meta.title
                    tab.name  = route.name
                    tab.path  = route.path
                }

                tab.query = route.query || {}
                tab.params = route.params || {}
                tab.props = { 
                    ...route.params,
                    ...tab.query
                }
                this.tabs.push(tab);
                this.add_to_history(tab);
            }
        },

        tab_history(tab) {
            this.$router.push({path: tab.path, query: tab.query || {}})
            this.add_to_history(tab);
            this.active_tab = tab.name
        },

        process_tab(route) {
            let this_name = route.name

            if (route.meta && route.meta.tab_with_props) {
                let name_postfix = Object.values(route.params).filter(n => n).join('-')
                this_name = route.name + name_postfix
                if (route.name === 'reporting_result')
                    this_name += route.query.p;
            }

            let exist_tab = this.tabs.find(t => t.name === this_name);
            if (!exist_tab) this.tab_add(route);
            else this.add_to_history(exist_tab);

            this.active_tab = this_name
        },

        check_route_access(route) {
            if (!route.meta || !route.meta.roles) return true;
            if (!this.user.groups) return true;

            let canShow = false
            route.meta.roles.forEach(meta_role => {
                canShow = (this.user.groups[meta_role]) ? true : false
            });
            return canShow;
        },

        get_stored_tabs(){
            if (localStorage.opened_tabs && this.routes.length) {
                let tabs = JSON.parse(localStorage.opened_tabs);
                tabs.forEach(tab => {
                    if (tab.name === 'home') return;

                    let exist_tab = this.tabs.find(t => t.name === tab.name);
                    if (exist_tab) return;

                    let route = undefined
                    
                    if (tab.tab_with_props) {
                        route = this.find_route_by_name(tab.route_name);
                        if (!route.params) route.params = {};
                        route.params = tab.params
                    }
                    else {
                        route = this.find_route_by_name(tab.name);
                    }

                    if (route) {
                        route.query = tab.query || {}
                        this.tab_add(route);
                    }
                })

                if (this.$route.meta && this.$route.meta.tab_with_props) {
                    let name_postfix = Object.values(this.$route.params).filter(n => n).join('-')
                    let this_name = this.$route.name + name_postfix
                    if (this.$route.name === 'reporting_result')
                        this_name += this.$route.query.p;
                    this.active_tab = this_name
                }
                else {
                    this.active_tab = this.$route.name
                }
            }
        },

        selected_search_result(result){
            if (result.pagetype === 'client'){
                this.$router.push('/client/' + result.id)
            }
            else if (result.pagetype === 'portfolio') {
                this.$router.push('/portfolio/' + result.client.id + '/' + result.id)
            }
            else if (result.pagetype === 'instrument') {
                this.$router.push('/library/instruments/' + result.assettype + '/' + result.isin)
            }
        },

        change_tab_title(tab_name, title){
            let index = this.tabs.findIndex(x => x.name === tab_name)
            let tab = this.tabs[index]
            if (tab) {
                tab.title = title
                this.$set(this.tabs, index, tab);
            }
        },

        change_tab_query(tab_name, params){
            let index = this.tabs.findIndex(x => x.name === tab_name)
            let tab = this.tabs[index]
            if (tab) {
                if (!tab.query) tab.query = {};
                tab.query[params.k] = params.v
                this.$set(this.tabs, index, tab);
            }
        },

        find_route_by_name(name){
            let route = this.routes.find(r => r.name === name);
            return route;
        },

        add_to_history(tab) {
            this.history.unshift(tab);

            this.history = this.history.reduce((unique, o) => {
                if(!unique.some(obj => obj.name === o.name)) {
                    unique.push(o);
                }
                return unique;
            },[]);

            this.history.length = Math.min(this.history.length, 15);
        },

        insert_version(){
            const tab_bar_header = this.$refs.TabsBar.$el.querySelector('.el-tabs__header')
            tab_bar_header.appendChild(this.$refs.Logo.$el)
        },

        update_portfolio_data($event){
            let clientid = $event.clientid
            let portfolioid = $event.portfolioid

            // вкладка с всеми портфелями клиента
            let client_tab_name = 'client' + clientid;
            if (this.$refs[client_tab_name] && this.$refs[client_tab_name][0]){
                this.$refs[client_tab_name][0].get_portfolios()
            }

            // вкладка с операциями клиента
            let operations_tab_name = 'operations' + clientid + '-' + portfolioid;
            if (this.$refs[operations_tab_name] && this.$refs[operations_tab_name][0]){
                this.$refs[operations_tab_name][0].get_list()
            }
            
            // вкладка с именно этим портфелем клиента
            let portfolio_tab_name = 'client_portfolio' + clientid + '-' + portfolioid;
            if (this.$refs[portfolio_tab_name] && this.$refs[portfolio_tab_name][0]){
                this.$refs[portfolio_tab_name][0].get_list()
            }
        },
    },

    watch: {
        '$route': function (to) {
            this.process_tab(to)
            this.sidebar = false
        },

        tabs(val) {
            localStorage.setItem('opened_tabs', JSON.stringify(val))
        },

        history(val) {
            localStorage.setItem('history_tabs', JSON.stringify(val))
        },

        user(to, from) {
            const saved_history = (localStorage.history_tabs) ? JSON.parse(localStorage.history_tabs) : []
            this.get_stored_tabs();
            this.history = saved_history
        },

        serverError(val) {
            if (val && (val == 401 || (val == 422 && this.serverErrorText === 'Not enough segments'))) {
                this.$message({
                    message: 'Authentication Error',
                    type: 'error'
                });
                this.$store.commit('app/set', {type: 'isAuthed', items: false})
            }
            else if (val && val == 412) {
                this.$message({
                    message: 'This record was changed by another user recently. Please, update your data.',
                    type: 'error'
                });
            }
            else if (val && this.serverErrorText) {
                this.$message({
                    message: this.serverErrorText,
                    type: 'error',
                    dangerouslyUseHTMLString: true
                });
            }
            else if (val) {
                this.$message({
                    message: 'Server Error',
                    type: 'error'
                });
            }
        },
    },

    created: function() {
        this.$store.dispatch('app/checkAuth')
    },

    async mounted() {
        await this.$nextTick()
        if (this.isAuthed) this.insert_version();

        if(localStorage.orkl_theme === 'dark') document.querySelector('body').classList.add('dark')
    },

    /*sockets: {
        connect() {
            console.log('socket connected')
        },
        new_notification(notification){
            console.log('new notification received')
            console.log(notification)
            //this.$message(notification)
        }
    }*/
}

</script>
