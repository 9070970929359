<template>
    <el-dialog
        :visible.sync="settings_dialog"
        title="Settings"
        custom-class="settings-dialog"
        append-to-body
        @close="settings_dialog = false"
        modal-append-to-body>
        <template slot="title">
            <div>
                <div class="settings-page-title">Settings</div>
            </div>
        </template>
        <div class="settings-page">
            <div class="settings-page-wrapper">
                <div class="setting-row">
                    <div class="setting-label">Выбор темы</div>
                    <div class="setting-control">
                        <svg-icon icon-class="light"></svg-icon>
                        <el-switch
                            v-model="settings.theme"
                            active-value="dark"
                            inactive-value="light"></el-switch>
                        <svg-icon icon-class="dark"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import {mapState} from 'vuex'

export default {
    data() {
        return {
            settings: {
                theme: 'light'
            }
        }
    },
    computed: {
        settings_dialog: {
            get() { return this.$store.state.app.settings_dialog; },
            set(value) { this.$store.commit('app/set', {type: 'settings_dialog', items:value}); },
        },

        current_theme: {
            get() {return this.$store.state.themes.current_theme},
            set(value) { this.$store.state.themes.current_theme = value}
        },
    },
    mounted() {
        this.settings.theme = this.current_theme
    },
    watch: {
        settings: {
            deep: true,
            handler(settings){
                this.$store.dispatch('themes/set_theme', {theme: settings.theme})

                if(settings.theme === 'dark'){
                    document.querySelector('body').classList.add('dark')
                } else {
                    document.querySelector('body').classList.remove('dark')
                }
            }
        }
    },
}
</script>