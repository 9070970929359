<template>
    <div class="logo">
        <div class="logo-title">ORKL</div>
        <div class="logo-version">
            <router-link to="/changelog">
                <el-link :underline="false">{{packageVersion}}</el-link>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            packageVersion : state => state.app.packageVersion,
        }),
    }
}
</script>
