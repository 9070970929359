<template>
    <div id="title-bar">
        <div id="title-bar-burger" @click="sidebar=true">
            <svg-icon icon-class="menu" />
        </div>
        <div id="title-bar-history">
            <el-dropdown placement="bottom-start" @command="handle_history_click">
                <div class="dropdown-holder"><svg-icon icon-class="history" /></div>

                <el-dropdown-menu slot="dropdown" class="history-menu">
                    <el-dropdown-item v-for="tab in history" :key="tab.name" :command="tab">
                        <div class="dropdown-row-holder">
                            <div class="dropdown-row-title">{{tab.title}}</div>
                            <div class="dropdown-row-arrow"><svg-icon icon-class="chevron_right" /></div>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div id="title-bar-search">
            <Search @selected="selected_search_result"/>
        </div>
        <div id="title-bar-user">
            <el-dropdown placement="bottom-start">
                <el-badge :value="notifications.length">
                    <svg-icon icon-class="user-male" />
                </el-badge>

                <el-dropdown-menu slot="dropdown" class="user-menu">
                    <el-dropdown-item><div @click="showEdit = true">Edit profile</div></el-dropdown-item>
                    <el-dropdown-item>Notification <el-badge :value="notifications.length"></el-badge></el-dropdown-item>
                    <el-dropdown-item><div @click="logout">Log out</div></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="username">{{user.firstname}} {{user.lastname}}</div>
        </div>
        <!-- <div id="title-bar-widgets">
            <div class="title">Widgets</div>
            <el-switch v-model="widgetsbar" class="switch">
            </el-switch>
        </div> -->

        <edit-profile :show="showEdit" @close-form="close_profile_edit"/>
    </div>
</template>

<script>
//import {io} from 'socket.io-client'
import { mapState }  from 'vuex'
import Search from './Search'
import EditProfile from "@/views/App/EditProfile";

export default {
    components: { Search, EditProfile },

    data() {
        return {
            showEdit: false,
            notifications_counter: 0,
            notifications_list: [],
            socket: null,
            stop_note_track: false,
        }
    },

    computed: {
        sidebar: {
            get() { return this.$store.state.app.sidebar; },
            set(value) { this.$store.commit('app/set', {type: 'sidebar', items:value}); },
        },
        widgetsbar: {
            get() { return this.$store.state.app.widgetsbar; },
            set(value) { this.$store.commit('app/set', {type: 'widgetsbar', items:value}); },
        },

        ...mapState({
            user : state => state.app.user,
            user_groups : state => state.userGroups.options,
            history : state => state.app.history,
            notifications: function(state){
                let notifications = state.notifications.list
                this.notifications_list = notifications
                return notifications
            }
        }),
    },

    /*watch: {
        notifications_list: {
            //deep: true,
            handler(val, oldVal){
                if(val != oldVal){
                    console.log('watch')
                    this.stop_note_track = true
                    let notifications = val
                    this.notification_counter = notifications.length
                    this.$message(`Notification: ${notifications[notifications.length - 1]}`);
                    this.stop_note_track = false
                }
            }
        }
    },*/

    methods: {
        selected_search_result(result) {
            this.$emit('selected-search-result', result);
        },

        handle_history_click(tab) {
            this.$emit('selected-history-tab', tab);
        },

        close_profile_edit() {
            this.showEdit = false
        },

        logout(){
            this.$store.dispatch('app/logout');
        }
    },

    //Будет срабатывать на каждой вкладке
    /*sockets: {
        connect() {
            console.log('socket connected')
        },
        new_notification(notification){
            console.log('new notification received')
            this.$message(notification)
        }
    }*/

/*    mounted() {
        if(!this.socket){
            if(this.socket)
                this.socket.removeAllListeners();
            this.socket = io("ws://127.0.0.1:5000/");
            this.socket.on('connect', (e) => {
                this.socket.send("Меня зовут Джон");
                console.log('ws connected')
            });

            this.socket.on('connect_error', (e) => {
                console.log('ws error')
            });

            this.socket.on("file_updated", (data) => {
                console.log('file_updated');
                console.log(data);
                console.log('notes count')
                console.log(data.length)
                this.notifications_counter = data.length
                this.$message(`Notification: ${data[data.length - 1]}`);
                this.$message(`Notification count: ${data.length}`);
            });
        }
    }*/
}
</script>
