import Vue                  from 'vue'
import axios                from 'axios'
import VueAxios             from 'vue-axios'
import Cookies              from 'js-cookie'
import VueJwtDecode         from 'vue-jwt-decode'
import { Message, Loading } from 'element-ui';
import Conf                 from '@/../config/url.js'
import { routerMap }        from '@/router/'
import router               from '@/router'

import {version}            from '@/../package.json';

function getAuthToken () {
    let accessToken  = localStorage.orkl_access;
    let refreshToken = localStorage.orkl_refresh;
    // if (process.env.NODE_ENV === 'development') console.log({accessToken: accessToken, refreshToken: refreshToken})
    if (accessToken && accessToken !== 'undefined' && refreshToken && refreshToken !== 'undefined' && VueJwtDecode.decode(accessToken).exp <= (Date.now()  / 1000).toFixed(0)) {
        if (process.env.NODE_ENV === 'development') console.log('token should be updated')
        let authTokenRequest = axios.post(Conf.tokenRefresh, {}, {
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                }
            })
            .then(response => {
                localStorage.orkl_access = response.data.access_token;
                return response.data.access_token
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') console.log(error.request);
                localStorage.removeItem('orkl_access');
                localStorage.removeItem('orkl_refresh');
            })
        return authTokenRequest
    }
    return accessToken
}

axios.defaults.timeout = 180000;

axios.interceptors.request.use(async function (config) {
    if (config.url !== Conf.login && config.url !== Conf.tokenRefresh) {
        let token = await getAuthToken()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (config) {
    return config
}, function (error) {
    if (error.request !== undefined && (error.request.responseURL === Conf.tokenRefresh || error.request.status === 401 && error.config.__isRetryRequest)) {
        localStorage.removeItem('orkl_access');
        localStorage.removeItem('orkl_refresh');
        Message.error('Authentication Error')
        return Promise.reject(error)
    }
    else if (error.request !== undefined && error.request.status === 401) {
        error.config.__isRetryRequest = true
        return axios.request(error.config)
    }

    return Promise.reject(error)
})

Vue.use(VueAxios, axios)

const state = {
    isAuthed           : false,
    user               : {},
    routes             : [],
    routes_plain       : [],
    serverError        : undefined,
    serverErrorText    : undefined,
    settings_dialog    : false,
    sidebar            : false,
    widgetsbar         : true,
    packageVersion     : version,
    global_search      : '',
    global_search_type : undefined,
    history            : [],
    widgetLibrary      : false,

    field_types: [
        {value: 'string',  label: 'Text'},
        {value: 'integer',  label: 'Integer'},
        {value: 'float',  label: 'Float'},
        {value: 'date',    label: 'Date'},
        {value: 'boolean', label: 'Yes/No'},
    ],

    field_sources: [
        {value: 'eikon',         label: 'Refinitiv Eikon'},
        {value: 'datastream',    label: 'DataStream'},
        {value: 'blackbox',      label: 'Blackbox'},
        {value: 'calculation',   label: 'Calculation'},
    ],

    field_formats: [
        {value: 'formatAmount',        label: 'Number'},
        {value: 'formatSignedNumber',  label: 'Number with Sign'},
        {value: 'formatSignedPercent', label: 'Number in Percents with Sign'},
        {value: 'formatSignedUsd',     label: 'Number in USD with Sign'},
        {value: 'formatUsd',           label: 'Number in USD'},
        {value: 'formatPercent',       label: 'Number in Percents'},
    ],

    field_owners: [
        {value: 'bond',    label: 'Bond'},
        {value: 'equity',  label: 'Equity'},
    ],

    field_dicts: [
        {value: 'lettered_rating',         label: 'lettered rating'},
        {value: 'lettered_moody_rating',   label: 'lettered moody rating'},
    ],
}

const getters = {
    accessToken: state => {
        let token = getAuthToken()
        return token
    },
}

const actions = {
    login({commit, dispatch}, data){
        commit('set', {type: 'isAuthed', items: false});

        Vue.axios.post(Conf.login, null, { params:data}).then(response => {
            let body = response.data
            if ('access_token' in body && body.access_token) {
                localStorage.orkl_access = body.access_token;
                localStorage.orkl_refresh = body.refresh_token;
                commit('set', {type: 'isAuthed', items: true});
                dispatch('getCurrentUser')
                router.push({ path: '/home' }).catch(()=>{});
            }
            else {
                Message.error('Authentication Error')
            }
        })
        .catch(error => {
            dispatch('process_error', error)
        })
    },

    checkAuth({commit, dispatch}) {
        let hasToken = (localStorage.orkl_access) ? true : false;
        commit('set', {type: 'isAuthed', items: hasToken});
        if (hasToken) dispatch('getCurrentUser')
    },

    getCurrentUser({commit, dispatch}){
        commit('set', {type: 'user', items: {}});

        Vue.axios.get(Conf.checkToken, {params: {ts: new Date().getTime()}}).then((response) => {
            let body = response.data
            body.id = body._id['$oid']
            commit('set', {type: 'user', items:body})
            dispatch('generateRoutes')
        })
        .catch(error => {
            dispatch('process_error', error)
        })
    },

    get_list({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'list', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })
        let params = {ts: new Date().getTime()}
        let st = rootState[props.name];

        if (st.pagination) {
            params.max_results = st.perPage
            params.page = st.currentPage
        }

        if(st.sortCol && st.sortDir){
            let direction = st.sortDir === 'asc' ? '' : '-'
            params.sort = direction + st.sortCol
        }

        if(st.filters) {
            let filters = st.filters
            let where = {}

            Object.keys(st.filters).forEach((filter) => {
                if(filters[filter].value) where[filter] = filters[filter].value
            })

            if(where && Object.keys(where).length !== 0) params.filters = where

            //Если фильтры поменялись, устанавливаем 1 страницу
            if(st.filterChanged && st.pagination){
                params.page = 1
                commit(props.name + '/set', {type: 'filterChanged', items: false}, { root: true })
            }
        }

        return Vue.axios.get(props.url, {params: params}).then((response) => {
            let body = response.data._items
            let meta = response.data._meta
            let list = ('process' in props) ? props.process(body) : body
            commit(props.name + '/set', {type: 'list', items:list}, { root: true })
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
            if (meta) {
                commit(props.name + '/set', {type: 'totalCount', items:meta.total}, { root: true })
                commit(props.name + '/set', {type: 'currentPage', items:meta.page}, { root: true })
                commit(props.name + '/set', {type: 'perPage', items:meta.max_results}, { root: true })
            }
            else {
                commit(props.name + '/set', {type: 'totalCount', items:list.length}, { root: true })
            }
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    get_options({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'options', items:[]}, { root: true })
        commit(props.name + '/set', {type: 'optionsHash', items:{}}, { root: true })
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })

        return Vue.axios.get(props.url, {params: {ts: new Date().getTime()}}).then((response) => {
            let body = response.data._items
            let options = []
            let optionsHash = {}
            body.forEach(key => {
                let label = ''
                if (typeof props.option_title === 'object') {
                    let label_array = []
                    props.option_title.forEach(k => {
                        label_array.push(key[k])
                    })
                    label = label_array.join(' ')
                }
                else {
                    label = key[props.option_title]
                }
                options.push({
                    label: label,
                    value: key._id,
                });
                optionsHash[key._id] = label
            });

            commit(props.name + '/set', {type: 'options', items:options}, { root: true })
            commit(props.name + '/set', {type: 'optionsHash', items:optionsHash}, { root: true })
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    add_item({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })
        commit('set', {type: 'serverError', items: undefined});

        let st = rootState[props.name]
        let data = ('preprocess' in props) ? props.preprocess(props.item) : props.item

        return Vue.axios.post(props.url, data).then(response => {
            let body = response.data
            let id = body._id
            if (!id) return;
            if (!st.list) {
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
                if (!props.noMessage) Message({
                    message: 'Added Successfully',
                    type: 'success'
                });
                return;
            }
            return Vue.axios.get(props.url + id).then((itemResponse) => {
                let item = itemResponse.data
                item = ('postprocess' in props) ? props.postprocess(item) : item
                let array = st.list
                array.unshift(item)

                let totalCount = st.totalCount + 1

                commit(props.name + '/set', {type: 'list', items:array}, { root: true })
                commit(props.name + '/set', {type: 'current', items:item}, { root: true })
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
                commit(props.name + '/set', {type: 'totalCount', items:totalCount}, { root: true })

                if (!props.noMessage) Message({
                    message: 'Added Successfully',
                    type: 'success'
                });
            })
            .catch(error => {
                dispatch('process_error', error)
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    update_item({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })
        commit('set', {type: 'serverError', items: undefined});
        let st = rootState[props.name];

        let reqparams = {
            headers: {
                'If-Match': props.item._etag
            }
        };

        let data = JSON.parse(JSON.stringify(props.item));

        ['_XID','_created','_etag','_links','_updated','_deleted'].forEach(key => {
            delete data[key]
        });

        data = ('preprocess' in props) ? props.preprocess(data) : data;

        return Vue.axios.patch(props.url + props.item._id, data, reqparams).then(response => {
            if (!st.list) {
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
                if (!props.noMessage) 
                    Message({
                        message: 'Edited Successfully',
                        type: 'success'
                    });
                return;
            }
            return Vue.axios.get(props.url + props.item._id).then((itemResponse) => {
                let item = itemResponse.data
                item = ('postprocess' in props) ? props.postprocess(item) : item
                let array = st.list
                let index = array.findIndex(x => x._id === item._id);
                Vue.set(array, index, item);

                commit(props.name + '/set', {type: 'list', items:array}, { root: true })
                commit(props.name + '/set', {type: 'current', items:item}, { root: true })
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })

                if (!props.noMessage) Message({
                    message: 'Edited Successfully',
                    type: 'success'
                })
            })
            .catch(error => {
                dispatch('process_error', error)
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    delete_item({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })
        commit('set', {type: 'serverError', items: undefined});
        let st = rootState[props.name]

        let reqparams = {
            headers: {
                'If-Match': props.data.etag
            }
        };

        return Vue.axios.delete(props.url + props.data.id, reqparams).then(response => {
            if (!st.list) {
                commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
                if (!props.noMessage) Message({
                    message: 'Deleted Successfully',
                    type: 'success'
                });
                return;
            }
            let array = st.list
            let index = array.findIndex(x => x._id === props.data.id)
            array.splice(index, 1)

            let totalCount = st.totalCount - 1

            commit(props.name + '/set', {type: 'list', items:array}, { root: true })
            commit(props.name + '/set', {type: 'current', items:{}}, { root: true })
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
            commit(props.name + '/set', {type: 'totalCount', items:totalCount}, { root: true })

            Message({
                message: 'Deleted Successfully',
                type: 'success'
            })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    get_excel({commit, dispatch, rootState}, props){
        commit(props.name + '/set', {type: 'dataLoading', items:true}, { root: true })

        return Vue.axios.get(props.url).then((response) => {
            let file = response.data.file
            window.open(file);

            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
        .catch(error => {
            dispatch('process_error', error)
            commit(props.name + '/set', {type: 'dataLoading', items:false}, { root: true })
        })
    },

    process_error({commit}, error){
        if (process.env.NODE_ENV === 'development') {
            if (error.request) console.log(error.request);
            else console.log(error);
        }
        if (error.request) {
            commit('set', {type: 'serverError', items: error.request.status});
            if ('response' in error.request) {
                let r = JSON.parse(error.request.response)
                if (typeof r === 'object' && 'msg' in r) {
                    commit('set', {type: 'serverErrorText', items: r.msg});
                }
            }
        }
    },

    generateRoutes({commit}){
        commit('set', {type: 'routes', items: [] });
        var routesArray = []

        var routesArray = routerMap.filter(function (rt) {

            let canShow = false

            if (!rt.hidden) {
                if (rt.meta && rt.meta.roles){
                    if (rt.meta && rt.meta.roles){
                        rt.meta.roles.forEach(meta_role => {
                            if (!canShow)
                                canShow = (state.user.rights[meta_role] || state.user.full_access) ? true : false
                        })
                    }
                }
                else {
                    canShow = true
                }
            }
            return canShow
        });

        commit('set', {type: 'routes_plain', items: routesArray });
        var routesTree = list_to_tree(routesArray)

        commit('set', {type: 'routes', items: routesTree });
    },

    logout({commit}) {
        localStorage.removeItem('orkl_access');
        localStorage.removeItem('orkl_refresh');
        localStorage.removeItem('opened_tabs');
        commit('set', {type: 'isAuthed', items: false});
        router.push({ path: '/home' }).catch(()=>{});
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    }
}

function list_to_tree(list) {
    var map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].name] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.meta && node.meta.parent && list[map[node.meta.parent]]) {
            list[map[node.meta.parent]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
