import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message } from 'element-ui'

const state = {
    list        : [],
    current     : {},
    totalCount  : 0,
    currentPage : 1,
    perPage     : 20,
    dataLoading : false,

    options     : [],
}

const module_name = 'clients'
const url = Urls.clients

var preprocessItem = function(item) {
    // item.fields = JSON.stringify(item.fields)
    return item
}

const actions = {
    getList({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    addItem({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    updateItem({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    deleteItem({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    getOptions({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : ['first_name', 'last_name']
        }

        return dispatch('app/get_options', props, { root: true })
    },

    getClient({dispatch}, id){
        let get_url = url + '?where={"id": ' + id + '}'
        return new Promise((resolve, reject) => {
            Vue.axios.get(get_url, {params: {ts: new Date().getTime()}})
            .then((response) => {
                if (response.data && response.data._items[0]){
                    resolve(response.data._items[0]);
                }
                else {
                    Message({
                        message: 'Cannot find client!',
                        type: 'error'
                    })
                    reject('Access restricted')
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    getClientsList({dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {params: {ts: new Date().getTime()}})
            .then((response) => {
                if (response.data && response.data._items){
                    resolve(response.data._items);
                }
                else {
                    reject('Access restricted')
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    }
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
